export const ENGLISH_TRANSLATIONS = {
	// MENU ITEMS
	menuItems: {
		home: 'Home',
		game: {
			title: 'Game',
			worlds: 'Worlds',
			medals: 'Badges',
			packages: 'Packages'
		},
		recipes: 'Recipes',
		ingredients: {
			title: 'Ingredients',
			ingredients: 'Ingredients',
			categories: 'Categories'
		},
		items: 'Cookware',
		units: 'Units',
		actions: 'Actions',
		users: 'Users',
		settings: 'Settings',
		logout: 'Logout'
	},

	// SETTINGS
	settings: {
		language: {
			options: {
				english: 'English',
				spanish: 'Spanish'
			}
		},
		account: {
			title: 'Account'
		}
	},

	// HOME
	home: {
		title: 'Welcome',
		subtitle: 'What are we going to do today?'
	},

	// NOT FOUND
	notFound: {
		subTitle: 'Sorry, the page you are looking for does not exist.',
		backHome: 'Back to Home'
	},

	// AUTHENTICATION
	auth: {
		title: 'Oops! You are not authorized to view this page.',
		subTitle: 'You will be redirected to the login page in'
	},

	// GAME - WORLDS
	worlds: {
		create: {
			modalTitle: 'Create World'
		},
		edit: {
			modalTitle: 'Edit World',
			successAlert: 'World updated successfully',
		},
		delete: {
			confirmTitle: 'Delete World',
			confirmText: 'Are you sure you want to delete this world?',
			successAlert: 'World deleted successfully',
			errorAlert: 'Error deleting world, please try again later'
		}
	},

	// GAME - MEDALS
	medals: {
		create: {
			modalTitle: 'Create Badge',
		},
		edit: {
			modalTitle: 'Edit Badge',
			successAlert: 'Badge updated successfully',
		},
		delete: {
			confirmTitle: 'Delete Badge',
			confirmText: 'Are you sure you want to delete this badge?',
			successAlert: 'Badge deleted successfully',
			errorAlert: 'Error deleting badge, please try again later'
		}
	},

	// GAME - LEVELS
	levels: {
		modalTitle: 'Level Configuration',
		toUnlock: 'To Unlock',
		initial: 'Initial',
		create: {
			successAlert: 'Level configuration created successfully',
			errorAlert: 'Error creating level configuration, please try again later'
		},
		edit: {
			successAlert: 'Level configuration updated successfully',
			errorAlert: 'Error updating level configuration, please try again later'
		},
		delete: {
			confirmTitle: 'Delete Level Configuration',
			confirmText: 'Are you sure you want to delete this level configuration?',
			successAlert: 'Level configuration deleted successfully',
			errorAlert: 'Error deleting level configuration, please try again later'
		}
	},

	// GAME - PACKAGES
	packages: {
		create: {
			modalTitle: 'Create Package',
			successAlert: 'Package created successfully',
			errorAlert: 'Error creating package, please try again later'
		},
		edit: {
			modalTitle: 'Edit Package',
			successAlert: 'Package updated successfully',
			errorAlert: 'Error updating package, please try again later'
		},
		delete: {
			confirmTitle: 'Delete Package',
			confirmText: 'Are you sure you want to delete this package?',
			successAlert: 'Package deleted successfully',
			errorAlert: 'Error deleting package, please try again later'
		}
	},

	// RECIPES
	recipes: {
		create: {
			title: 'Create Recipe',
			recipeDataTitle: 'Recipe Data',
			successAlert: 'Recipe created successfully',
		},
		edit: {
			successAlert: 'Recipe updated successfully',
		},
		delete: {
			confirmTitle: 'Delete Recipe',
			confirmText: 'Are you sure you want to delete this recipe?',
			successAlert: 'Recipe deleted successfully',
			errorAlert: 'Error deleting recipe, please try again later'
		},
		ingredients: {
			addIngredients: 'Add Ingredients'
		},
		details: {
			title: 'Recipe Details',
			video: 'Tutorial Video',
			pdf: 'View PDF',
			creationDate: 'Creation Date:'
		}
	},

	// STEPS
	steps: {
		title: 'Steps',
		form: {
			mainInformation: 'Main Information',
			finalIngredient: 'Final Ingredient',
			globalAction: 'Global Action'
		},
		create: {
			errorAlert: 'Error creating step, close the form and try again later.',
		},
		delete: {
			confirmTitle: 'Delete Step',
			confirmText: 'Are you sure you want to delete this step?',
			successAlert: 'Step deleted successfully',
			errorAlert: 'Error deleting step, please try again later'
		}
	},

	// STEPS - RULES
	rules: {
		title: 'Rule',
		create: {
			modalTitle: 'Create Rule',
			successAlert: 'Rule created successfully',
			errorAlert: 'Error creating rule, please try again later'
		},
		edit: {
			modalTitle: 'Edit Rule',
			successAlert: 'Rule updated successfully',
			errorAlert: 'Error updating rule, please try again later'
		},
		delete: {
			confirmTitle: 'Delete Rule',
			confirmText: 'Are you sure you want to delete this rule?',
			successAlert: 'Rule deleted successfully',
			errorAlert: 'Error deleting rule, please try again later'
		}
	},

	// STEPS - CHALLENGES
	challenges: {
		title: 'Challenge',
		create: {
			modalTitle: 'Create Challenge',
			successAlert: 'Challenge created successfully',
			errorAlert: 'Error creating challenge, please try again later'
		},
		edit: {
			modalTitle: 'Edit Challenge',
			successAlert: 'Challenge updated successfully',
			errorAlert: 'Error updating challenge, please try again later'
		},
		delete: {
			confirmTitle: 'Delete Challenge',
			confirmText: 'Are you sure you want to delete this challenge?',
			successAlert: 'Challenge deleted successfully',
			errorAlert: 'Error deleting challenge, please try again later'
		}
	},

	// INGREDIENTS
	ingredients: {
		create: {
			modalTitle: 'Create Ingredient'
		},
		edit: {
			modalTitle: 'Edit Ingredient',
			successAlert: 'Ingredient updated successfully',
		},
		delete: {
			confirmTitle: 'Delete Ingredient',
			confirmText: 'Are you sure you want to delete this ingredient?',
			successAlert: 'Ingredient deleted successfully',
			errorAlert: 'Error deleting ingredient, please try again later'
		},
		states: {
			title: 'States',
			hasProperties: 'Indicates that the ingredient state has properties.',
			create: {
				modalTitle: 'Create State'
			},
			edit: {
				modalTitle: 'Edit State',
				successAlert: 'State updated successfully'
			},
			delete: {
				confirmTitle: 'Delete State',
				confirmText: 'Are you sure you want to delete this state?',
				successAlert: 'State deleted successfully',
				errorAlert: 'Error deleting state, please try again later'
			},
		},
		properties: {
			title: 'Properties',
			state: {
				title: 'State Property',
				info: 'Information',
			},
			create: {
				modalTitle: 'Create Property'
			},
			edit: {
				modalTitle: 'Edit Property',
				successAlert: 'Property updated successfully'
			},
			delete: {
				confirmTitle: 'Delete Property',
				confirmText: 'Are you sure you want to delete this property?',
				successAlert: 'Property deleted successfully',
				errorAlert: 'Error deleting property, please try again later'
			},
		},
		categories: {
			title: 'Ingredient Categories',
			create: {
				modalTitle: 'Create Category',
				successAlert: 'Category created successfully',
				errorAlert: 'Error creating category, please try again later'
			},
			edit: {
				modalTitle: 'Edit Category',
				successAlert: 'Category updated successfully',
				errorAlert: 'Error updating category, please try again later'
			},
			delete: {
				confirmTitle: 'Delete Category',
				confirmText: 'Are you sure you want to delete this category?',
				successAlert: 'Category deleted successfully',
				errorAlert: 'Error deleting category, please try again later'
			}
		},
	},

	// ITEMS
	items: {
		create: {
			modalTitle: 'Create Utensil',
			successAlert: 'Utensil created successfully',
			errorAlert: 'Error creating utensil, please try again later'
		},
		edit: {
			modalTitle: 'Edit Utensil',
			successAlert: 'Utensil updated successfully',
			errorAlert: 'Error updating utensil, please try again later'
		},
		delete: {
			confirmTitle: 'Delete Utensil',
			confirmText: 'Are you sure you want to delete this utensil?',
			successAlert: 'Utensil deleted successfully',
			errorAlert: 'Error deleting utensil, please try again later'
		}
	},

	// UNITS
	units: {
		create: {
			modalTitle: 'Create Unit'
		},
		edit: {
			modalTitle: 'Edit Unit',
			successAlert: 'Unit updated successfully',
		},
		delete: {
			confirmTitle: 'Delete Unit',
			confirmText: 'Are you sure you want to delete this unit?',
			successAlert: 'Unit deleted successfully',
			errorAlert: 'Error deleting unit, please try again later'
		}
	},

	// ACTIONS
	actions: {
		create: {
			modalTitle: 'Create Action'
		},
		edit: {
			modalTitle: 'Edit Action',
			successAlert: 'Action updated successfully',
		},
		delete: {
			confirmTitle: 'Delete Action',
			confirmText: 'Are you sure you want to delete this action?',
			successAlert: 'Action deleted successfully',
			errorAlert: 'Error deleting action, please try again later'
		},
		types: {
			title: 'Types',
			hasHaptics: 'Indicates that the action type has haptics.',
			create: {
				modalTitle: 'Create Type'
			},
			edit: {
				modalTitle: 'Edit Type',
				successAlert: 'Type updated successfully'
			},
			delete: {
				confirmTitle: 'Delete Type',
				confirmText: 'Are you sure you want to delete this type?',
				successAlert: 'Type deleted successfully',
				errorAlert: 'Error deleting type, please try again later'
			}
		},
		haptics: {
			title: 'Haptics',
			create: {
				modalTitle: 'Create Haptic'
			},
			edit: {
				modalTitle: 'Edit Haptic',
				successAlert: 'Haptic updated successfully'
			},
			delete: {
				confirmTitle: 'Delete Haptic',
				confirmText: 'Are you sure you want to delete this haptic?',
				successAlert: 'Haptic deleted successfully',
				errorAlert: 'Error deleting haptic, please try again later'
			}
		}
	},

	// USERS
	users: {
		create: {
			modalTitle: 'Create User'
		},
		edit: {
			modalTitle: 'Edit User',
			successAlert: 'User updated successfully',
		},
		editPassword: {
			successAlert: 'Password updated successfully',
			errorAlert: 'Error updating password, please try again later',
		},
		delete: {
			confirmTitle: 'Delete User',
			confirmText: 'Are you sure you want to delete this user?',
			successAlert: 'User deleted successfully',
			errorAlert: 'Error deleting user, please try again later'
		},
		details: {
			title: 'User Details',
			progress: 'Progress',
			difficultyError: 'There are no difficulties available to assign to the user.',
			unlockSuccess: 'Recipe unlocked successfully',
			unlockError: 'Error unlocking recipe, please try again later'
		}
	},

	// BUTTONS
	buttons: {
		add: 'Add',
		all: 'All',
		cancel: 'Cancel',
		confirm: 'Confirm',
		continue: 'Continue',
		create: 'Create',
		delete: 'Delete',
		details: 'Details',
		edit: 'Edit',
		finish: 'Finish',
		reorder: 'Reorder',
		reset: 'Reset',
		save: 'Save',
		search: 'Search',
		upload: 'Upload'
	},

	// FORM
	form: {
		label: {
			name: 'Name',
			description: 'Description',
			image: 'Image',
			nationality: 'Nationality',
			color: 'Color',
			world: 'World',
			video: 'Video',
			startVideo: 'Start of video',
			endVideo: 'End of video',
			ingredient: 'Ingredient',
			initialState: 'Initial State',
			finalState: 'Final State',
			quantity: 'Qty.',
			unit: 'Unit',
			price: 'Price',
			pdf: 'PDF File',
			scale: 'Scale',
			texture: 'Texture',
			lightning: 'Lightning',
			abbreviation: 'Abbreviation',
			language: 'Language',
			first_name: 'First Name',
			last_name: 'Last Name',
			email: 'Email',
			username: 'Username',
			password: 'Password',
			confirm: 'Confirm Password',
			sprite: 'Sprite Sheet',
			recipe_type: 'Step Type',
			instruction: 'Instruction',
			state: 'State',
			action: 'Action',
			actionType: 'Action Type',
			equipment: 'Kitchen Equipment',
			hapticType: 'Haptic Type',
			element: 'Element',
			destiny: 'Destiny',
			screenPercentage: 'Screen Percentage',
			nPointers: 'Number of Pointers',
			mirrored: 'Mirrored',
			begin: 'Begin',
			end: 'End',
			speedRate: 'Speed Rate',
			holdTime: 'Hold Time',
			magnitude: 'Magnitude',
			direction: 'Direction',
			angle: 'Angle',
			repeats: 'Repeats',
			rotateDirection: 'Rotate Direction',
			repeats: 'Repeats',
			itemType: 'Item Type',
			resultGoesToBowl: 'Move Result',
			medalType: 'Badge Type',
			medalValue: 'Badge Value',
			optimalValue: 'Optimal Value',
			offset: 'Offset',
			points: 'Points',
			challengeValue: 'Challenge Value',
			challengeType: 'Challenge Type',
			hasProductAsset: 'Product Image',
			matter: 'Matter State',
			itemUsage: 'Item Usage',
			itemContentScale: 'Content Scale',
			type: 'Type',
			category: 'Category',
			particles: 'Particles',
			purchaseLevelConfig: 'Purchase required',
			content: 'Content',
		},
		placeholders: {
			name: 'Enter name',
			description: 'Enter description',
			nationality: 'Enter nationality',
			color: 'Enter color',
			world: 'Select world',
			video: 'Enter video URL',
			videoTime: 'Time in seconds',
			ingredient: 'Select ingredient',
			quantity: 'Enter quantity',
			unit: 'Select unit',
			price: 'Enter price',
			pdf: 'Click to Upload',
			scale: 'Enter scale',
			searchIngredient: 'Search Ingredient',
			texture: 'Enter texture',
			lightning: 'Enter lightning',
			searchItem: 'Search Utensil',
			abbreviation: 'Enter abbreviation',
			language: 'Select language',
			first_name: 'Enter first name',
			last_name: 'Enter last name',
			email: 'Enter email',
			username: 'Enter username',
			password: 'Enter password',
			confirm: 'Confirm password',
			recipe_type: 'Select step type',
			instruction: 'Enter instruction',
			state: 'Select state',
			action: 'Select action',
			actionType: 'Select action type',
			cookware: 'Select cookware',
			equipment: 'Enter kitchen equipment',
			select: 'Select',
			enter: 'Enter',
			challengeValue: {
				time: 'Percentage of time',
				haptic: 'Percentage of precision',
				value: 'Value (temperature, speed, etc.)',
				order: 'Order'
			}		
		},
		rules: {
			required: 'This field is required',
			url: 'Enter a valid link',
			pdf: 'is not a PDF file, please upload a PDF file.',
			email: 'Enter a valid email',
			passwordMatch: 'The passwords do not match',
			hapticSliceNumPointers: 'The number must be 1 or 2',
			fillableItem: 'There can only be one fillable (F) utensil',
			holdableItem: 'There can only be one holdable (H) utensil'
		},
		update: {
			noChangesAlert: 'No changes were made. Try modifying a field before saving.'
		}
	},

	// TABLES
	table: {
		headers: {
			actions: 'Actions'
		},
		pagination: {
			of: 'of',
			page: 'Page',
		},
		error: {
			noData: 'No data'
		},
		copyableText: {
			copy: 'Copy',
			copied: 'Copied',
			copyId: 'Copy ID',
			copiedId: 'ID Copied'
		}
	},

	// STATUS / TYPES DESCRIPTIONS
	statusDesc: {
		status: {
			available: 'Available',
			blocked: 'Blocked',
			comingSoon: 'Coming Soon',
			disabled: 'Disabled',
			played: 'Played'
		},
		bool: {
			true: 'True',
			false: 'False'
		},
		stepTypes: {
			empty: 'Game',
			both: 'Game and content',
			content: 'Content'
		},
		haptictypes: {
			drag: 'Drag',
			slice: 'Slice',
			tap: 'Tap',
			shake: 'Shake',
			directedMove: 'Directed Move',
			rotate: 'Rotate',
			freeze: 'Freeze',
			interactWithOverlay: 'Interact With Overlay'
		},
		hapticDragElementTypes: {
			ingredient: 'Ingredient',
			item: 'Item'
		},
		hapticAlignments: {
			topLeft: 'Top Left',
			topCenter: 'Top Center',
			topRight: 'Top Right',
			centerLeft: 'Center Left',
			center: 'Center',
			centerRight: 'Center Right',
			bottomLeft: 'Bottom Left',
			bottomCenter: 'Bottom Center',
			bottomRight: 'Bottom Right'
		},
		hapticMagnitudes: {
			low: 'Low',
			normal: 'Normal',
			hard: 'Hard'
		},
		hapticSpeedRates: {
			slow: 'Slow',
			medium: 'Medium',
			fast: 'Fast'
		},
		hapticsAxis: {
			horizontal: 'Horizontal',
			vertical: 'Vertical'
		},
		hapticsAxisDirections: {
			up: 'Up',
			right: 'Right',
			down: 'Down',
			left: 'Left'
		},
		itemTypes: {
			fillable: 'Fillable',
			holdable: 'Holdable',
		},
		equipmentOptions: {
			stove: 'Stove',
			oven: 'Oven',
			microwave: 'Microwave',
			fridge: 'Fridge',
			dishwasher: 'Dishwasher',
			table: 'Table',
			cuttingTable: 'Cutting Table',
			bowl: 'Bowl',
			tray: 'Tray',
			plate: 'Plate',
		},
		medalTypes: {
			time: 'Time',
			world: 'World',
			challenge: 'Challenge',
			recipe: 'Recipe',
			score: 'Score'
		},
		challengeTypes: {
			time: 'Time',
			haptic: 'Haptic',
			value: 'Value',
			order: 'Order'
		},
		propertiesMatter: {
			solid: 'Solid',
			liquid: 'Liquid',
			gas: 'Gas',
			other: 'Other'
		},
		itemUsage: {
			top: 'Top',
			glass: 'Glass'
		},
		ingredientType: {
			normal: 'Normal',
			compound: 'Compound'
		}
	}
};
