import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Alert, Button, Checkbox, Form, Input, InputNumber, Modal, Select, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import ImageUpload from '../../utils/ImageUpload';

// Actions
import { get_all_items, set_item_create_id, item_create, item_update, clear_items_errors } from '../../../redux/actions/itemsActions';
import { form_image_upload } from '../../../redux/actions/utilsActions';

// Utils
import isEmpty from '../../../utils/isEmpty';
import { item_types_options, item_usage_options } from '../../../utils/select_options';
import { create_haptic_select_options, create_select_options_from_array } from '../../../utils/create_select_options';

const ItemsForm = (props) => {
	const { messageApi, isModalOpen, setIsModalOpen, update, itemToUpdate, setItemToUpdate, filters } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { item_create_loading, item_id, item_update_loading, items_errors } = useSelector(state => state.items);

	const [fileList, setFileList] = useState([]);
	const [spriteList, setSpriteList] = useState([]);
	const [showContentScale, setShowContentScale] = useState(false);

	const ITEM_TYPES = item_types_options();
	const ITEM_USAGES = item_usage_options();
	
	const [form] = Form.useForm();

	useEffect(() => {
		if (!isEmpty(itemToUpdate)) {
			form.setFieldsValue(itemToUpdate);

			if (itemToUpdate.item_type === 1) {
				setShowContentScale(true);
			}
		}
	}, [itemToUpdate]);

	const handleCancel = (finish) => {
		form.resetFields();
		setFileList([]);
		setSpriteList([]);
		setIsModalOpen(false);
		setItemToUpdate({});
		setShowContentScale(false);
		dispatch(set_item_create_id(null));
		dispatch(clear_items_errors());

		if (finish) {
			dispatch(get_all_items(filters));
		}
	}

	const onValuesChange = (changedValues, allValues) => {
		if (allValues.item_type === 1) {
			setShowContentScale(true);
		} else {
			setShowContentScale(false);
		}
	}

	const onOk = () => {
		form
			.validateFields()
			.then((values) => {
				if (!update) {
					// Create
					values.content_scale = values.content_scale || 1;
					dispatch(item_create(values));
				}
				else {
					// Update
					dispatch(item_update(messageApi, item_id, values, () => handleCancel(true)));
				}
			})
			.catch((info) => {
				console.error(info);
			});
	}

	return (
		<Modal
			title={!update ? t('items.create.modalTitle') : t('items.edit.modalTitle')}
			centered
			open={isModalOpen}
			onCancel={() => handleCancel(false)}
			maskClosable={false}
			width={700}
			footer={[
				<Button
					key='submit'
					type='primary'
					loading={!update ? item_create_loading : item_update_loading}
					onClick={item_id && !update ? () => handleCancel(true) : onOk}
				>
					{update
						?	t('buttons.edit')
						:	(item_id ? t('buttons.finish') : t('buttons.create'))
					}
				</Button>,
			]}
		>
			<Form
				labelCol={{ span: 6 }}
				form={form}
				onValuesChange={onValuesChange}
				initialValues={{ content_scale: 1, particles: false }}
			>
				{items_errors.hasOwnProperty('item_create') &&
					<Alert
						style={{ textAlign: 'start', marginBottom: '1.5rem' }}
						message={t('items.create.errorAlert')}
						type='error'
						showIcon
					/>
				}
				{items_errors.hasOwnProperty('item_update') &&
					<Alert
						style={{ textAlign: 'start', marginBottom: '1.5rem' }}
						message={t('items.edit.errorAlert')}
						type='error'
						showIcon
					/>
				}
				<Form.Item
					label={t('form.label.name')}
					name='name'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						}
					]}
				>
					<Input placeholder={t('form.placeholders.name')} />
				</Form.Item>
				<Form.Item
					label={t('form.label.description')}
					name='description'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						}
					]}
				>
					<Input placeholder={t('form.placeholders.description')} />
				</Form.Item>
				<Form.Item
					label={t('form.label.scale')}
					name='scale'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						}
					]}
				>
					<InputNumber />
				</Form.Item>
				<Form.Item
					label={t('form.label.itemType')}
					name='item_type'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						}
					]}
				>
					<Select
						showSearch
						placeholder={`${t('form.placeholders.select')} ${t('form.label.itemType')}`}
						optionFilterProp='children'
						filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
						options={create_select_options_from_array(ITEM_TYPES)}
					/>
				</Form.Item>
				{showContentScale && (
					<Form.Item
						label={t('form.label.itemContentScale')}
						name='content_scale'
						rules={[
							{
								required: true,
								message: t('form.rules.required'),
							}
						]}
					>
						<InputNumber />
					</Form.Item>
				)}
				<Form.Item
					label={t('form.label.itemUsage')}
					name='usage'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						}
					]}
				>
					<Select
						showSearch
						placeholder={`${t('form.placeholders.select')} ${t('form.label.itemUsage')}`}
						optionFilterProp='children'
						filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
						options={create_haptic_select_options(ITEM_USAGES)}
					/>
				</Form.Item>
				<Form.Item
					label={t('form.label.particles')}
					name='particles'
					valuePropName='checked'
				>
					<Checkbox />
				</Form.Item>
			</Form>
			{item_id && (
				<Space align='start' size={10}>
					<Typography.Text style={{ marginLeft: '4.3rem' }}>{t('form.label.image')}:</Typography.Text>
					<ImageUpload
						action={process.env.REACT_APP_SERVER_URL + `/api/recipes/items/${item_id}/asset/upload`}
						actionFunction={(options) => dispatch(form_image_upload(options))}
						fileList={fileList}
						setFileList={setFileList}
					/>
					<Typography.Text style={{ marginLeft: '4.3rem' }}>{t('form.label.sprite')}:</Typography.Text>
					<ImageUpload
						action={process.env.REACT_APP_SERVER_URL + `/api/recipes/items/${item_id}/sprite/upload`}
						actionFunction={(options) => dispatch(form_image_upload(options))}
						fileList={spriteList}
						setFileList={setSpriteList}
					/>
				</Space>
			)}
		</Modal>
	)
}

ItemsForm.propTypes = {
	messageApi: PropTypes.object.isRequired,
	isModalOpen: PropTypes.bool.isRequired,
	setIsModalOpen: PropTypes.func.isRequired,
	update: PropTypes.bool.isRequired,
	itemToUpdate: PropTypes.object.isRequired,
	setItemToUpdate: PropTypes.func.isRequired,
	filters: PropTypes.object.isRequired
}

export default ItemsForm;
