import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Alert, Button, ColorPicker, Form, Input, InputNumber, Modal, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Actions
import { clear_properties_errors, get_all_properties, is_property_form_modal_open, property_create, property_update } from '../../../redux/actions/propertiesActions';

// Utils
import isEmpty from '../../../utils/isEmpty';
import { format_hex_color_with_alpha, transform_color_format } from '../../../utils/colors_handlers';
import { properties_matter_options } from '../../../utils/select_options';
import { create_select_options_from_array } from '../../../utils/create_select_options';

const PropertiesForm = (props) => {
	const { messageApi, ingredientId, updateProperty, setUpdateProperty, propertyToUpdate, setPropertyToUpdate } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { property_form_modal_open, property_create_loading, property_update_loading, properties_errors } = useSelector(state => state.properties);

	const [isStateProperty, setIsStateProperty] = useState(false);
	const [propertiesMatterOPtions, setPropertiesMatterOPtions] = useState([]);

	const PROPERTIES_MATTERS = properties_matter_options();
	
	const [form] = Form.useForm();

	useEffect(() => {
		setPropertiesMatterOPtions(create_select_options_from_array(PROPERTIES_MATTERS));
	}, []);

	useEffect(() => {
		if (!isEmpty(propertyToUpdate)) {
			form.setFieldsValue({
				name: propertyToUpdate?.name,
				description: propertyToUpdate?.description,
				color: transform_color_format(propertyToUpdate?.color),
				texture: propertyToUpdate?.texture,
				lightning: propertyToUpdate?.lightning,
				quantity: propertyToUpdate?.quantity,
				matter: propertyToUpdate?.matter
			});
			setIsStateProperty(propertyToUpdate?.state?.$oid ? true : false);
		}
	}, [propertyToUpdate]);

	const handleCancel = (finish) => {
		form.resetFields();
		dispatch(is_property_form_modal_open(false));
		setUpdateProperty(false);
		setPropertyToUpdate({});
		dispatch(clear_properties_errors());

		if (finish) {
			// If true, get ingredient property
			dispatch(get_all_properties({
				ingredient: propertyToUpdate?.ingredient?.$oid,
				...(isStateProperty ? { state: propertyToUpdate?.state?.$oid, scope: 3 } : { scope: 2 })
			}));
		}
	}

	const onOk = () => {
		form
			.validateFields()
			.then((values) => {
				const hexString = typeof values.color === 'string' ? values.color : values.color?.toHex();
				values.color = format_hex_color_with_alpha(hexString);
				
				if (!updateProperty) {
					// Create
					const body = {
						...values,
						ingredient: propertyToUpdate?.ingredient?.$oid,
						...(propertyToUpdate?.state?.$oid
							? { state: propertyToUpdate?.state?.$oid, scope: 3 } 
							: { scope: 2 }
						)
					};
					dispatch(property_create(body, () => handleCancel(!isStateProperty), isStateProperty, ingredientId));
				}
				else {
					// Update
					dispatch(property_update(messageApi, propertyToUpdate?._id?.$oid, values, () => handleCancel(!isStateProperty), isStateProperty, ingredientId));
				}
			})
			.catch((info) => {
				console.error(info);
			});
	}

	return (
		<Modal
			title={!updateProperty ? t('ingredients.properties.create.modalTitle') : t('ingredients.properties.edit.modalTitle')}
			centered
			open={property_form_modal_open}
			onCancel={() => handleCancel(false)}
			maskClosable={false}
			width={700}
			footer={[
				<Button
					key='submit'
					type='primary'
					loading={!updateProperty ? property_create_loading : property_update_loading}
					onClick={onOk}
				>
					{updateProperty
						?	t('buttons.edit')
						:	t('buttons.create')
					}
				</Button>,
			]}
		>
			{properties_errors.hasOwnProperty('property_create') &&
				<Alert
					style={{ textAlign: 'start', marginBottom: '1.5rem' }}
					message={properties_errors.property_create.msg}
					type='error'
					showIcon
				/>
			}
			{properties_errors.hasOwnProperty('property_update') &&
				<Alert
					style={{ textAlign: 'start', marginBottom: '1.5rem' }}
					message={properties_errors.property_update.msg}
					type='error'
					showIcon
				/>
			}
			<Form
				labelCol={{ span: 6 }}
				form={form}
			>
				<Form.Item
					label={t('form.label.name')}
					name='name'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						}
					]}
				>
					<Input placeholder={t('form.placeholders.name')} />
				</Form.Item>
				<Form.Item
					label={t('form.label.description')}
					name='description'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						}
					]}
				>
					<Input placeholder={t('form.placeholders.description')} />
				</Form.Item>
				<Form.Item
					label={t('form.label.color')}
					name='color'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						}
					]}
				>
					<ColorPicker format='hex' defaultValue={transform_color_format(propertyToUpdate?.color)} />
				</Form.Item>
				<Form.Item
					label={t('form.label.texture')}
					name='texture'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						}
					]}
				>
					<Input placeholder={t('form.placeholders.texture')} />
				</Form.Item>
				<Form.Item
					label={t('form.label.scale')}
					name='lightning'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						}
					]}
				>
					<InputNumber  style={{ width: '100%' }} placeholder={t('form.placeholders.scale')} />
				</Form.Item>
				<Form.Item
					label={t('form.label.quantity')}
					name='quantity'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						}
					]}
				>
					<InputNumber  style={{ width: '100%' }} placeholder={t('form.placeholders.quantity')} />
				</Form.Item>
				<Form.Item
					label={t('form.label.matter')}
					name='matter'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						}
					]}
				>
					<Select
						showSearch
						placeholder={t('form.placeholders.recipe_type')}
						optionFilterProp='children'
						filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
						options={propertiesMatterOPtions}
					/>
				</Form.Item>
			</Form>
		</Modal>
	)
}

PropertiesForm.propTypes = {
	messageApi: PropTypes.object.isRequired,
	ingredientId: PropTypes.string.isRequired,
	updateProperty: PropTypes.bool.isRequired,
	setUpdateProperty: PropTypes.func.isRequired,
	propertyToUpdate: PropTypes.object.isRequired,
	setPropertyToUpdate: PropTypes.func.isRequired
}

export default PropertiesForm;
