// Auth
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const USER_INIT = 'USER_INIT';
export const USER_INIT_CORRECT = 'USER_INIT_CORRECT';
export const USER_INIT_ERROR = 'USER_INIT_ERROR';

// Users
export const CLEAR_ALL_USERS_DATA = 'CLEAR_ALL_USERS_DATA';
export const CLEAR_ALL_USERS_ERRORS = 'CLEAR_ALL_USERS_ERRORS';
export const CLEAR_USERS_SINGLE_ERROR = 'CLEAR_USERS_SINGLE_ERROR';

export const USER_CREATE_LOADING = 'USER_CREATE_LOADING';
export const USER_CREATE_ERROR = 'USER_CREATE_ERROR';

export const ALL_USERS_LOADING = 'ALL_USERS_LOADING';
export const ALL_USERS_GET = 'ALL_USERS_GET';
export const ALL_USERS_ERROR = 'ALL_USERS_ERROR';

export const USER_INFO_LOADING = 'USER_INFO_LOADING';
export const USER_INFO_GET = 'USER_INFO_GET';
export const USER_INFO_ERROR = 'USER_INFO_ERROR';

export const USER_UPDATE_LOADING = 'USER_UPDATE_LOADING';
export const USER_UPDATE_ERROR = 'USER_UPDATE_ERROR';

export const USER_PASSWORD_UPDATE_LOADING = 'USER_PASSWORD_UPDATE_LOADING';
export const USER_PASSWORD_UPDATE_ERROR = 'USER_PASSWORD_UPDATE_ERROR';

export const USER_DELETE_LOADING = 'USER_DELETE_LOADING';

// Recipes
export const CLEAR_ALL_RECIPES_DATA = 'CLEAR_ALL_RECIPES_DATA';
export const CLEAR_ALL_RECIPES_ERRORS = 'CLEAR_ALL_RECIPES_ERRORS';
export const CLEAR_RECIPES_ERROR = 'CLEAR_RECIPES_ERROR';

export const SET_RECIPE_ID = 'SET_RECIPE_ID';
export const RECIPE_CREATE_LOADING = 'RECIPE_CREATE_LOADING';
export const RECIPE_CREATE_ERROR = 'RECIPE_CREATE_ERROR';

export const ALL_RECIPES_LOADING = 'ALL_RECIPES_LOADING';
export const ALL_RECIPES_GET = 'ALL_RECIPES_GET';
export const ALL_RECIPES_ERROR = 'ALL_RECIPES_ERROR';

export const RECIPE_DATA_LOADING = 'RECIPE_DATA_LOADING';
export const RECIPE_DATA_GET = 'RECIPE_DATA_GET';
export const RECIPE_DATA_ERROR = 'RECIPE_DATA_ERROR';

export const RECIPE_UPDATE_LOADING = 'RECIPE_UPDATE_LOADING';
export const RECIPE_UPDATE_ERROR = 'RECIPE_UPDATE_ERROR';

export const RECIPE_DELETE_LOADING = 'RECIPE_DELETE_LOADING';

export const RECIPE_GAME_DATA_LOADING = 'RECIPE_GAME_DATA_LOADING';
export const RECIPE_GAME_DATA_GET = 'RECIPE_GAME_DATA_GET';
export const RECIPE_GAME_DATA_ERROR = 'RECIPE_GAME_DATA_ERROR';

export const UNLOCK_USER_RECIPE_LOADING = 'UNLOCK_USER_RECIPE_LOADING';
export const UNLOCK_USER_RECIPE_SUCCESS = 'UNLOCK_USER_RECIPE_SUCCESS';
export const UNLOCK_USER_RECIPE_ERROR = 'UNLOCK_USER_RECIPE_ERROR';

// Recipes - Ingredients
export const RECIPE_INGREDIENT_CREATE_LOADING = 'RECIPE_INGREDIENT_CREATE_LOADING';
export const RECIPE_INGREDIENT_CREATE_ERROR = 'RECIPE_INGREDIENT_CREATE_ERROR';

export const ALL_RECIPE_INGREDIENTS_LOADING = 'ALL_RECIPE_INGREDIENTS_LOADING';
export const ALL_RECIPE_INGREDIENTS_GET = 'ALL_RECIPE_INGREDIENTS_GET';
export const ALL_RECIPE_INGREDIENTS_ERROR = 'ALL_RECIPE_INGREDIENTS_ERROR';

export const RECIPE_INGREDIENT_DELETE_LOADING = 'RECIPE_INGREDIENT_DELETE_LOADING';

// Recipes - Steps
export const CLEAR_ALL_STEPS_DATA = 'CLEAR_ALL_STEPS_DATA';
export const CLEAR_ALL_STEPS_ERRORS = 'CLEAR_ALL_STEPS_ERRORS';
export const CLEAR_STEPS_SINGLE_ERROR = 'CLEAR_STEPS_SINGLE_ERROR';

export const STEP_CREATE_LOADING = 'STEP_CREATE_LOADING';
export const STEP_CREATE_ERROR = 'STEP_CREATE_ERROR';

export const ALL_STEPS_LOADING = 'ALL_STEPS_LOADING';
export const ALL_STEPS_GET = 'ALL_STEPS_GET';
export const ALL_STEPS_ERROR = 'ALL_STEPS_ERROR';

export const STEP_INFO_LOADING = 'STEP_INFO_LOADING';
export const STEP_INFO_GET = 'STEP_INFO_GET';
export const STEP_INFO_ERROR = 'STEP_INFO_ERROR';

export const STEP_UPDATE_LOADING = 'STEP_UPDATE_LOADING';
export const STEP_UPDATE_ERROR = 'STEP_UPDATE_ERROR';

export const ALL_STEPS_UPDATE_LOADING = 'ALL_STEPS_UPDATE_LOADING';
export const ALL_STEPS_UPDATE_ERROR = 'ALL_STEPS_UPDATE_ERROR';

export const STEP_DELETE_LOADING = 'STEP_DELETE_LOADING';

export const STEP_GAME_MODAL_OPEN = 'STEP_GAME_MODAL_OPEN';

// Recipes - Steps - Rules
export const CLEAR_ALL_RULES_DATA = 'CLEAR_ALL_RULES_DATA';
export const CLEAR_ALL_RULES_ERRORS = 'CLEAR_ALL_RULES_ERRORS';
export const CLEAR_RULES_SINGLE_ERROR = 'CLEAR_RULES_SINGLE_ERROR';

export const RULE_CREATE_LOADING = 'RULE_CREATE_LOADING';
export const SET_RULE_ID = 'SET_RULE_ID';
export const RULE_CREATE_ERROR = 'RULE_CREATE_ERROR';

export const ALL_RULES_LOADING = 'ALL_RULES_LOADING';
export const ALL_RULES_GET = 'ALL_RULES_GET';
export const ALL_RULES_ERROR = 'ALL_RULES_ERROR';

export const RULE_INFO_LOADING = 'RULE_INFO_LOADING';
export const RULE_INFO_GET = 'RULE_INFO_GET';
export const RULE_INFO_ERROR = 'RULE_INFO_ERROR';

export const RULE_UPDATE_LOADING = 'RULE_UPDATE_LOADING';
export const RULE_UPDATE_ERROR = 'RULE_UPDATE_ERROR';

export const RULE_DELETE_LOADING = 'RULE_DELETE_LOADING';

// Recipes - Levels
export const CLEAR_ALL_LEVELS_DATA = 'CLEAR_ALL_LEVELS_DATA';
export const CLEAR_ALL_LEVELS_ERRORS = 'CLEAR_ALL_LEVELS_ERRORS';
export const CLEAR_LEVELS_SINGLE_ERROR = 'CLEAR_LEVELS_SINGLE_ERROR';

export const LEVEL_CREATE_LOADING = 'LEVEL_CREATE_LOADING';
export const SET_LEVEL_ID = 'SET_LEVEL_ID';
export const LEVEL_CREATE_ERROR = 'LEVEL_CREATE_ERROR';

export const ALL_LEVELS_LOADING = 'ALL_LEVELS_LOADING';
export const ALL_LEVELS_GET = 'ALL_LEVELS_GET';
export const ALL_LEVELS_ERROR = 'ALL_LEVELS_ERROR';

export const LEVEL_INFO_LOADING = 'LEVEL_INFO_LOADING';
export const LEVEL_INFO_GET = 'LEVEL_INFO_GET';
export const LEVEL_INFO_ERROR = 'LEVEL_INFO_ERROR';

export const LEVEL_UPDATE_LOADING = 'LEVEL_UPDATE_LOADING';
export const LEVEL_UPDATE_ERROR = 'LEVEL_UPDATE_ERROR';

export const LEVEL_DELETE_LOADING = 'LEVEL_DELETE_LOADING';

// Ingredients
export const CLEAR_ALL_INGREDIENTS_DATA = 'CLEAR_ALL_INGREDIENTS_DATA';
export const CLEAR_ALL_INGREDIENTS_ERRORS = 'CLEAR_ALL_INGREDIENTS_ERRORS';
export const CLEAR_INGREDIENTS_ERROR = 'CLEAR_INGREDIENTS_ERROR';

export const INGREDIENT_CREATE_LOADING = 'INGREDIENT_CREATE_LOADING';
export const SET_INGREDIENT_ID = 'SET_INGREDIENT_ID';
export const INGREDIENT_CREATE_ERROR = 'INGREDIENT_CREATE_ERROR';

export const ALL_INGREDIENTS_LOADING = 'ALL_INGREDIENTS_LOADING';
export const ALL_INGREDIENTS_GET = 'ALL_INGREDIENTS_GET';
export const ALL_INGREDIENTS_ERROR = 'ALL_INGREDIENTS_ERROR';

export const INGREDIENT_INFO_LOADING = 'INGREDIENT_INFO_LOADING';
export const INGREDIENT_INFO_GET = 'INGREDIENT_INFO_GET';
export const INGREDIENT_INFO_ERROR = 'INGREDIENT_INFO_ERROR';

export const INGREDIENT_UPDATE_LOADING = 'INGREDIENT_UPDATE_LOADING';
export const INGREDIENT_UPDATE_ERROR = 'INGREDIENT_UPDATE_ERROR';

export const INGREDIENT_DELETE_LOADING = 'RECIPE_DELETE_LOADING';

// Ingredients - States
export const CLEAR_ALL_STATES_DATA = 'CLEAR_ALL_STATES_DATA';
export const CLEAR_ALL_STATES_ERRORS = 'CLEAR_ALL_STATES_ERRORS';
export const CLEAR_STATES_ERROR = 'CLEAR_STATES_ERROR';

export const STATE_CREATE_LOADING = 'STATE_CREATE_LOADING';
export const SET_STATE_ID = 'SET_STATE_ID';
export const STATE_CREATE_ERROR = 'STATE_CREATE_ERROR';

export const ALL_STATES_LOADING = 'ALL_STATES_LOADING';
export const ALL_STATES_GET = 'ALL_STATES_GET';
export const ALL_STATES_ERROR = 'ALL_STATES_ERROR';

export const STATE_INFO_LOADING = 'STATE_INFO_LOADING';
export const STATE_INFO_GET = 'STATE_INFO_GET';
export const STATE_INFO_ERROR = 'STATE_INFO_ERROR';

export const STATE_UPDATE_LOADING = 'STATE_UPDATE_LOADING';
export const STATE_UPDATE_ERROR = 'STATE_UPDATE_ERROR';

export const STATE_DELETE_LOADING = 'STATE_DELETE_LOADING';

// Ingredients - Categories
export const CLEAR_ALL_CATEGORIES_DATA = 'CLEAR_ALL_CATEGORIES_DATA';
export const CLEAR_ALL_CATEGORIES_ERRORS = 'CLEAR_ALL_CATEGORIES_ERRORS';
export const CLEAR_CATEGORIES_SINGLE_ERROR = 'CLEAR_CATEGORIES_SINGLE_ERROR';

export const CATEGORY_CREATE_LOADING = 'CATEGORY_CREATE_LOADING';
export const SET_CATEGORY_ID = 'SET_CATEGORY_ID';
export const CATEGORY_CREATE_ERROR = 'CATEGORY_CREATE_ERROR';

export const ALL_CATEGORIES_LOADING = 'ALL_CATEGORIES_LOADING';
export const ALL_CATEGORIES_GET = 'ALL_CATEGORIES_GET';
export const ALL_CATEGORIES_ERROR = 'ALL_CATEGORIES_ERROR';

export const CATEGORY_INFO_LOADING = 'CATEGORY_INFO_LOADING';
export const CATEGORY_INFO_GET = 'CATEGORY_INFO_GET';
export const CATEGORY_INFO_ERROR = 'CATEGORY_INFO_ERROR';

export const CATEGORY_UPDATE_LOADING = 'CATEGORY_UPDATE_LOADING';
export const CATEGORY_UPDATE_ERROR = 'CATEGORY_UPDATE_ERROR';

export const CATEGORY_DELETE_LOADING = 'CATEGORY_DELETE_LOADING';

// Items
export const CLEAR_ALL_ITEMS_DATA = 'CLEAR_ALL_ITEMS_DATA';
export const CLEAR_ALL_ITEMS_ERRORS = 'CLEAR_ALL_ITEMS_ERRORS';
export const CLEAR_ITEMS_SINGLE_ERROR = 'CLEAR_ITEMS_SINGLE_ERROR';

export const ITEM_CREATE_LOADING = 'ITEM_CREATE_LOADING';
export const SET_ITEM_ID = 'SET_ITEM_ID';
export const ITEM_CREATE_ERROR = 'ITEM_CREATE_ERROR';

export const ALL_ITEMS_LOADING = 'ALL_ITEMS_LOADING';
export const ALL_ITEMS_GET = 'ALL_ITEMS_GET';
export const ALL_ITEMS_ERROR = 'ALL_ITEMS_ERROR';

export const ITEM_INFO_LOADING = 'ITEM_INFO_LOADING';
export const ITEM_INFO_GET = 'ITEM_INFO_GET';
export const ITEM_INFO_ERROR = 'ITEM_INFO_ERROR';

export const ITEM_UPDATE_LOADING = 'ITEM_UPDATE_LOADING';
export const ITEM_UPDATE_ERROR = 'ITEM_UPDATE_ERROR';

export const ITEM_DELETE_LOADING = 'ITEM_DELETE_LOADING';

// Game - Worlds
export const CLEAR_ALL_WORLDS_DATA = 'CLEAR_ALL_WORLDS_DATA';
export const CLEAR_ALL_WORLDS_ERRORS = 'CLEAR_ALL_WORLDS_ERRORS';
export const CLEAR_WORLDS_SINGLE_ERROR = 'CLEAR_WORLDS_SINGLE_ERROR';

export const WORLD_CREATE_LOADING = 'WORLD_CREATE_LOADING';
export const SET_WORLD_ID = 'SET_WORLD_ID';
export const WORLD_CREATE_ERROR = 'WORLD_CREATE_ERROR';

export const ALL_WORLDS_LOADING = 'ALL_WORLDS_LOADING';
export const ALL_WORLDS_GET = 'ALL_WORLDS_GET';
export const ALL_WORLDS_ERROR = 'ALL_WORLDS_ERROR';

export const WORLD_INFO_LOADING = 'WORLD_INFO_LOADING';
export const WORLD_INFO_GET = 'WORLD_INFO_GET';
export const WORLD_INFO_ERROR = 'WORLD_INFO_ERROR';

export const WORLD_UPDATE_LOADING = 'WORLD_UPDATE_LOADING';
export const WORLD_UPDATE_ERROR = 'WORLD_UPDATE_ERROR';

export const WORLD_DELETE_LOADING = 'WORLD_DELETE_LOADING';

// Game - Medals
export const CLEAR_ALL_MEDALS_DATA = 'CLEAR_ALL_MEDALS_DATA';
export const CLEAR_ALL_MEDALS_ERRORS = 'CLEAR_ALL_MEDALS_ERRORS';
export const CLEAR_MEDALS_SINGLE_ERROR = 'CLEAR_MEDALS_SINGLE_ERROR';

export const MEDAL_CREATE_LOADING = 'MEDAL_CREATE_LOADING';
export const SET_MEDAL_ID = 'SET_MEDAL_ID';
export const MEDAL_CREATE_ERROR = 'MEDAL_CREATE_ERROR';

export const ALL_MEDALS_LOADING = 'ALL_MEDALS_LOADING';
export const ALL_MEDALS_GET = 'ALL_MEDALS_GET';
export const ALL_MEDALS_ERROR = 'ALL_MEDALS_ERROR';

export const MEDAL_INFO_LOADING = 'MEDAL_INFO_LOADING';
export const MEDAL_INFO_GET = 'MEDAL_INFO_GET';
export const MEDAL_INFO_ERROR = 'MEDAL_INFO_ERROR';

export const MEDAL_UPDATE_LOADING = 'MEDAL_UPDATE_LOADING';
export const MEDAL_UPDATE_ERROR = 'MEDAL_UPDATE_ERROR';

export const MEDAL_DELETE_LOADING = 'MEDAL_DELETE_LOADING';

// Game - Challenges
export const CLEAR_ALL_CHALLENGES_DATA = 'CLEAR_ALL_CHALLENGES_DATA';
export const CLEAR_ALL_CHALLENGES_ERRORS = 'CLEAR_ALL_CHALLENGES_ERRORS';
export const CLEAR_CHALLENGES_SINGLE_ERROR = 'CLEAR_CHALLENGES_SINGLE_ERROR';

export const CHALLENGE_CREATE_LOADING = 'CHALLENGE_CREATE_LOADING';
export const SET_CHALLENGE_ID = 'SET_CHALLENGE_ID';
export const CHALLENGE_CREATE_ERROR = 'CHALLENGE_CREATE_ERROR';

export const ALL_CHALLENGES_LOADING = 'ALL_CHALLENGES_LOADING';
export const ALL_CHALLENGES_GET = 'ALL_CHALLENGES_GET';
export const ALL_CHALLENGES_ERROR = 'ALL_CHALLENGES_ERROR';

export const CHALLENGE_INFO_LOADING = 'CHALLENGE_INFO_LOADING';
export const CHALLENGE_INFO_GET = 'CHALLENGE_INFO_GET';
export const CHALLENGE_INFO_ERROR = 'CHALLENGE_INFO_ERROR';

export const CHALLENGE_UPDATE_LOADING = 'CHALLENGE_UPDATE_LOADING';
export const CHALLENGE_UPDATE_ERROR = 'CHALLENGE_UPDATE_ERROR';

export const CHALLENGE_DELETE_LOADING = 'CHALLENGE_DELETE_LOADING';

// Game - Packages
export const CLEAR_ALL_PACKAGES_DATA = 'CLEAR_ALL_PACKAGES_DATA';
export const CLEAR_ALL_PACKAGES_ERRORS = 'CLEAR_ALL_PACKAGES_ERRORS';
export const CLEAR_PACKAGES_SINGLE_ERROR = 'CLEAR_PACKAGES_SINGLE_ERROR';

export const PACKAGE_CREATE_LOADING = 'PACKAGE_CREATE_LOADING';
export const SET_PACKAGE_ID = 'SET_PACKAGE_ID';
export const PACKAGE_CREATE_ERROR = 'PACKAGE_CREATE_ERROR';

export const ALL_PACKAGES_LOADING = 'ALL_PACKAGES_LOADING';
export const ALL_PACKAGES_GET = 'ALL_PACKAGES_GET';
export const ALL_PACKAGES_ERROR = 'ALL_PACKAGES_ERROR';

export const PACKAGE_INFO_LOADING = 'PACKAGE_INFO_LOADING';
export const PACKAGE_INFO_GET = 'PACKAGE_INFO_GET';
export const PACKAGE_INFO_ERROR = 'PACKAGE_INFO_ERROR';

export const PACKAGE_UPDATE_LOADING = 'PACKAGE_UPDATE_LOADING';
export const PACKAGE_UPDATE_ERROR = 'PACKAGE_UPDATE_ERROR';

export const PACKAGE_DELETE_LOADING = 'PACKAGE_DELETE_LOADING';

// Game - Difficulties
export const ALL_DIFFICULTIES_LOADING = 'ALL_DIFFICULTIES_LOADING';
export const ALL_DIFFICULTIES_GET = 'ALL_DIFFICULTIES_GET';
export const ALL_DIFFICULTIES_ERROR = 'ALL_DIFFICULTIES_ERROR';

// Units
export const CLEAR_ALL_UNITS_DATA = 'CLEAR_ALL_UNITS_DATA';
export const CLEAR_ALL_UNITS_ERRORS = 'CLEAR_ALL_UNITS_ERRORS';
export const CLEAR_UNITS_SINGLE_ERROR = 'CLEAR_UNITS_SINGLE_ERROR';

export const UNIT_CREATE_LOADING = 'UNIT_CREATE_LOADING';
export const UNIT_CREATE_ERROR = 'UNIT_CREATE_ERROR';

export const ALL_UNITS_LOADING = 'ALL_UNITS_LOADING';
export const ALL_UNITS_GET = 'ALL_UNITS_GET';
export const ALL_UNITS_ERROR = 'ALL_UNITS_ERROR';

export const UNIT_INFO_LOADING = 'UNIT_INFO_LOADING';
export const UNIT_INFO_GET = 'UNIT_INFO_GET';
export const UNIT_INFO_ERROR = 'UNIT_INFO_ERROR';

export const UNIT_UPDATE_LOADING = 'UNIT_UPDATE_LOADING';
export const UNIT_UPDATE_ERROR = 'UNIT_UPDATE_ERROR';

export const UNIT_DELETE_LOADING = 'UNIT_DELETE_LOADING';

// Properties
export const CLEAR_ALL_PROPERTIES_DATA = 'CLEAR_ALL_PROPERTIES_DATA';
export const CLEAR_ALL_PROPERTIES_ERRORS = 'CLEAR_ALL_PROPERTIES_ERRORS';
export const CLEAR_PROPERTIES_SINGLE_ERROR = 'CLEAR_PROPERTIES_SINGLE_ERROR';

export const PROPERTY_FORM_MODAL_OPEN = 'PROPERTY_FORM_MODAL_OPEN';

export const PROPERTY_CREATE_LOADING = 'PROPERTY_CREATE_LOADING';
export const PROPERTY_CREATE_ERROR = 'PROPERTY_CREATE_ERROR';

export const ALL_PROPERTIES_LOADING = 'ALL_PROPERTIES_LOADING';
export const ALL_PROPERTIES_GET = 'ALL_PROPERTIES_GET';
export const ALL_PROPERTIES_ERROR = 'ALL_PROPERTIES_ERROR';

export const PROPERTY_INFO_LOADING = 'PROPERTY_INFO_LOADING';
export const PROPERTY_INFO_GET = 'PROPERTY_INFO_GET';
export const PROPERTY_INFO_ERROR = 'PROPERTY_INFO_ERROR';

export const PROPERTY_UPDATE_LOADING = 'PROPERTY_UPDATE_LOADING';
export const PROPERTY_UPDATE_ERROR = 'PROPERTY_UPDATE_ERROR';

export const PROPERTY_DELETE_LOADING = 'PROPERTY_DELETE_LOADING';

// Actions
export const CLEAR_ALL_ACTIONS_DATA = 'CLEAR_ALL_ACTIONS_DATA';
export const CLEAR_ALL_ACTIONS_ERRORS = 'CLEAR_ALL_ACTIONS_ERRORS';
export const CLEAR_ACTIONS_SINGLE_ERROR = 'CLEAR_ACTIONS_SINGLE_ERROR';

export const ACTION_CREATE_LOADING = 'ACTION_CREATE_LOADING';
export const ACTION_CREATE_ERROR = 'ACTION_CREATE_ERROR';

export const ALL_ACTIONS_LOADING = 'ALL_ACTIONS_LOADING';
export const ALL_ACTIONS_GET = 'ALL_ACTIONS_GET';
export const ALL_ACTIONS_ERROR = 'ALL_ACTIONS_ERROR';

export const ACTION_INFO_LOADING = 'ACTION_INFO_LOADING';
export const ACTION_INFO_GET = 'ACTION_INFO_GET';
export const ACTION_INFO_ERROR = 'ACTION_INFO_ERROR';

export const ACTION_UPDATE_LOADING = 'ACTION_UPDATE_LOADING';
export const ACTION_UPDATE_ERROR = 'ACTION_UPDATE_ERROR';

export const ACTION_DELETE_LOADING = 'ACTION_DELETE_LOADING';

// Actions - Types
export const CLEAR_ALL_TYPES_DATA = 'CLEAR_ALL_TYPES_DATA';
export const CLEAR_ALL_TYPES_ERRORS = 'CLEAR_ALL_TYPES_ERRORS';
export const CLEAR_TYPES_SINGLE_ERROR = 'CLEAR_TYPES_SINGLE_ERROR';

export const TYPE_CREATE_LOADING = 'TYPE_CREATE_LOADING';
export const TYPE_CREATE_ERROR = 'TYPE_CREATE_ERROR';

export const ALL_TYPES_LOADING = 'ALL_TYPES_LOADING';
export const ALL_TYPES_GET = 'ALL_TYPES_GET';
export const ALL_TYPES_ERROR = 'ALL_TYPES_ERROR';

export const TYPE_INFO_LOADING = 'TYPE_INFO_LOADING';
export const TYPE_INFO_GET = 'TYPE_INFO_GET';
export const TYPE_INFO_ERROR = 'TYPE_INFO_ERROR';

export const TYPE_UPDATE_LOADING = 'TYPE_UPDATE_LOADING';
export const TYPE_UPDATE_ERROR = 'TYPE_UPDATE_ERROR';

export const TYPE_DELETE_LOADING = 'TYPE_DELETE_LOADING';

// Actions - Haptics
export const CLEAR_ALL_HAPTICS_DATA = 'CLEAR_ALL_HAPTICS_DATA';
export const CLEAR_ALL_HAPTICS_ERRORS = 'CLEAR_ALL_HAPTICS_ERRORS';
export const CLEAR_HAPTICS_SINGLE_ERROR = 'CLEAR_HAPTICS_SINGLE_ERROR';

export const HAPTIC_CREATE_LOADING = 'HAPTIC_CREATE_LOADING';
export const HAPTIC_CREATE_ERROR = 'HAPTIC_CREATE_ERROR';

export const ALL_HAPTICS_LOADING = 'ALL_HAPTICS_LOADING';
export const ALL_HAPTICS_GET = 'ALL_HAPTICS_GET';
export const ALL_HAPTICS_ERROR = 'ALL_HAPTICS_ERROR';

export const HAPTIC_INFO_LOADING = 'HAPTIC_INFO_LOADING';
export const HAPTIC_INFO_GET = 'HAPTIC_INFO_GET';
export const HAPTIC_INFO_ERROR = 'HAPTIC_INFO_ERROR';

export const HAPTIC_UPDATE_LOADING = 'HAPTIC_UPDATE_LOADING';
export const HAPTIC_UPDATE_ERROR = 'HAPTIC_UPDATE_ERROR';

export const HAPTIC_DELETE_LOADING = 'HAPTIC_DELETE_LOADING';
