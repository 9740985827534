export const SPANISH_TRANSLATIONS = {
	// MENU ITEMS
	menuItems: {
		home: 'Inicio',
		game: {
			title: 'Juego',
			worlds: 'Mundos',
			medals: 'Pines',
			packages: 'Paquetes'
		},
		recipes: 'Recetas',
		ingredients: {
			title: 'Ingredientes',
			ingredients: 'Ingredientes',
			categories: 'Categorías'
		},
		items: 'Utensilios',
		units: 'Unidades',
		actions: 'Acciones',
		users: 'Usuarios',
		settings: 'Configuración',
		logout: 'Cerrar sesión'
	},

	// SETTINGS
	settings: {
		language: {
			options: {
				english: 'Inglés',
				spanish: 'Español'
			}
		},
		account: {
			title: 'Cuenta'
		}
	},

	// HOME
	home: {
		title: '¡Bienvenido',
		subtitle: '¿Qué vamos a hacer hoy?'
	},

	// NOT FOUND
	notFound: {
		subTitle: 'Lo sentimos, la página que buscas no existe.',
		backHome: 'Regresar a Inicio'
	},

	// AUTHENTICATION
	auth: {
		title: '¡Ups! No estás autorizado para ver esta página.',
		subTitle: 'Serás redirigido a la página de inicio de sesión en'
	},

	// GAME - WORLDS
	worlds: {
		create: {
			modalTitle: 'Crear Mundo'
		},
		edit: {
			modalTitle: 'Editar Mundo',
			successAlert: 'Mundo actualizado correctamente'
		},
		delete: {
			confirmTitle: 'Eliminar Mundo',
			confirmText: '¿Estás seguro de que quieres eliminar este mundo?',
			successAlert: 'Mundo eliminado correctamente',
			errorAlert: 'Error al eliminar el mundo, intenta de nuevo mas tarde'
		}
	},

	// GAME - MEDALS
	medals: {
		create: {
			modalTitle: 'Crear Pin'
		},
		edit: {
			modalTitle: 'Editar Pin',
			successAlert: 'Pin actualizado correctamente'
		},
		delete: {
			confirmTitle: 'Eliminar Pin',
			confirmText: '¿Estás seguro de que quieres eliminar este pin?',
			successAlert: 'Pin eliminado correctamente',
			errorAlert: 'Error al eliminar el pin, intenta de nuevo mas tarde'
		}
	},

	// GAME - LEVELS
	levels: {
		modalTitle: 'Configuración de Nivel',
		toUnlock: 'A desbloquear',
		initial: 'Inicial',
		create: {
			successAlert: 'Configuración creada correctamente.',
			errorAlert: 'Error al crear la configuración, intenta de nuevo mas tarde'
		},
		edit: {
			successAlert: 'Configuración actualizada correctamente',
			errorAlert: 'Error al actualizar la configuración, intenta de nuevo mas tarde'
		},
		delete: {
			confirmTitle: 'Eliminar Configuración',
			confirmText: '¿Estás seguro de que quieres eliminar esta configuración?',
			successAlert: 'Configuración eliminada correctamente',
			errorAlert: 'Error al eliminar la configuración, intenta de nuevo mas tarde'
		}
	},

	// GAME - PACKAGES
	packages: {
		create: {
			modalTitle: 'Crear Paquete',
			successAlert: 'Paquete creado correctamente',
			errorAlert: 'Error al crear paquete, intenta de nuevo mas tarde'
		},
		edit: {
			modalTitle: 'Editar Paquete',
			successAlert: 'Paquete actualizado correctamente',
			errorAlert: 'Error al actualizar el paquete, intenta de nuevo mas tarde'
		},
		delete: {
			confirmTitle: 'Eliminar Paquete',
			confirmText: '¿Estás seguro de que quieres eliminar este paquete?',
			successAlert: 'Paquete eliminado correctamente',
			errorAlert: 'Error al eliminar el paquete, intenta de nuevo mas tarde'
		}
	},

	// RECIPES
	recipes: {
		create: {
			title: 'Crear Receta',
			recipeDataTitle: 'Datos de la Receta',
			successAlert: 'Receta creada correctamente',
		},
		edit: {
			successAlert: 'Receta actualizada correctamente',
		},
		delete: {
			confirmTitle: 'Eliminar Receta',
			confirmText: '¿Estás seguro de que quieres eliminar esta receta?',
			successAlert: 'Receta eliminada correctamente',
			errorAlert: 'Error al eliminar la receta, intenta de nuevo mas tarde'
		},
		ingredients: {
			addIngredients: 'Agregar Ingredientes'
		},
		details: {
			title: 'Detalles de la Receta',
			video: 'Video Tutorial',
			pdf: 'Ver PDF',
			creationDate: 'Fecha de Creación:'
		}
	},

	// STEPS
	steps: {
		title: 'Pasos',
		form: {
			mainInformation: 'Información Principal',
			finalIngredient: 'Ingrediente Final',
			globalAction: 'Acción Global'
		},
		create: {
			errorAlert: 'Error al crear paso, intenta de nuevo mas tarde',
		},
		delete: {
			confirmTitle: 'Eliminar Paso',
			confirmText: '¿Estás seguro de que quieres eliminar este paso?',
			successAlert: 'Paso eliminado correctamente',
			errorAlert: 'Error al eliminar el paso, intenta de nuevo mas tarde'
		}
	},

	// STEPS - RULES
	rules: {
		title: 'Regla',
		create: {
			modalTitle: 'Crear Regla',
			successAlert: 'Regla creada correctamente',
			errorAlert: 'Error al crear regla, intenta de nuevo mas tarde'
		},
		edit: {
			modalTitle: 'Editar Regla',
			successAlert: 'Regla actualizada correctamente',
			errorAlert: 'Error al actualizar la regla, intenta de nuevo mas tarde'
		},
		delete: {
			confirmTitle: 'Eliminar Regla',
			confirmText: '¿Estás seguro de que quieres eliminar esta regla?',
			successAlert: 'Regla eliminada correctamente',
			errorAlert: 'Error al eliminar la regla, intenta de nuevo mas tarde'
		}
	},

	// STEPS - CHALLENGES
	challenges: {
		title: 'Desafío',
		create: {
			modalTitle: 'Crear Desafío',
			successAlert: 'Desafío creado correctamente',
			errorAlert: 'Error al crear desafío, intenta de nuevo mas tarde'
		},
		edit: {
			modalTitle: 'Editar Desafío',
			successAlert: 'Desafío actualizado correctamente',
			errorAlert: 'Error al actualizar el desafío, intenta de nuevo mas tarde'
		},
		delete: {
			confirmTitle: 'Eliminar Desafío',
			confirmText: '¿Estás seguro de que quieres eliminar este desafío?',
			successAlert: 'Desafío eliminado correctamente',
			errorAlert: 'Error al eliminar el desafío, intenta de nuevo mas tarde'
		}
	},

	// INGREDIENTS
	ingredients: {
		create: {
			modalTitle: 'Crear Ingrediente',
		},
		edit: {
			modalTitle: 'Editar Ingrediente',
			successAlert: 'Ingrediente actualizado correctamente',
		},
		delete: {
			confirmTitle: 'Eliminar Ingrediente',
			confirmText: '¿Estás seguro de que quieres eliminar este ingrediente?',
			successAlert: 'Ingrediente eliminado correctamente',
			errorAlert: 'Error al eliminar el ingrediente, intenta de nuevo mas tarde'
		},
		states: {
			title: 'Estados',
			hasProperties: 'Indica que el estado del ingrediente tiene propiedades.',
			create: {
				modalTitle: 'Crear Estado'
			},
			edit: {
				modalTitle: 'Editar Estado',
				successAlert: 'Estado actualizado correctamente'
			},
			delete: {
				confirmTitle: 'Eliminar Estado',
				confirmText: '¿Estás seguro de que quieres eliminar este estado?',
				successAlert: 'Estado eliminado correctamente',
				errorAlert: 'Error al eliminar el estado, intenta de nuevo mas tarde'
			},
		},
		properties: {
			title: 'Propiedades',
			state: {
				title: 'Propiedad de Estado',
				info: 'Información',
			},
			create: {
				modalTitle: 'Crear Propiedad'
			},
			edit: {
				modalTitle: 'Editar Propiedad',
				successAlert: 'Propiedad actualizada correctamente'
			},
			delete: {
				confirmTitle: 'Eliminar Propiedad',
				confirmText: '¿Estás seguro de que quieres eliminar esta propiedad?',
				successAlert: 'Propiedad eliminada correctamente',
				errorAlert: 'Error al eliminar la propiedad, intenta de nuevo mas tarde'
			},
		},
		categories: {
			title: 'Categorías de Ingredientes',
			create: {
				modalTitle: 'Crear Categoría',
				successAlert: 'Categoría creada correctamente',
				errorAlert: 'Error al crear categoría, intenta de nuevo mas tarde'
			},
			edit: {
				modalTitle: 'Editar Categoría',
				successAlert: 'Categoría actualizada correctamente',
				errorAlert: 'Error al actualizar la categoría, intenta de nuevo mas tarde'
			},
			delete: {
				confirmTitle: 'Eliminar Categoría',
				confirmText: '¿Estás seguro de que quieres eliminar esta categoría?',
				successAlert: 'Categoría eliminada correctamente',
				errorAlert: 'Error al eliminar la categoría, intenta de nuevo mas tarde'
			}
		}
	},

	// ITEMS
	items: {
		create: {
			modalTitle: 'Crear Utensilio',
			successAlert: 'Utensilio creado correctamente.',
			errorAlert: 'Error al crear el utensilio, intenta de nuevo mas tarde'
		},
		edit: {
			modalTitle: 'Editar Utensilio',
			successAlert: 'Utensilio actualizado correctamente',
			errorAlert: 'Error al actualizar el utensilio, intenta de nuevo mas tarde'
		},
		delete: {
			confirmTitle: 'Eliminar Utensilio',
			confirmText: '¿Estás seguro de que quieres eliminar este utensilio?',
			successAlert: 'Utensilio eliminado correctamente',
			errorAlert: 'Error al eliminar el utensilio, intenta de nuevo mas tarde'
		}
	},

	// UNITS
	units: {
		create: {
			modalTitle: 'Crear Unidad'
		},
		edit: {
			modalTitle: 'Editar Unidad',
			successAlert: 'Unidad actualizada correctamente',
		},
		delete: {
			confirmTitle: 'Eliminar Unidad',
			confirmText: '¿Estás seguro de que quieres eliminar esta unidad?',
			successAlert: 'Unidad eliminada correctamente',
			errorAlert: 'Error al eliminar la unidad, intenta de nuevo mas tarde'
		}
	},

	// ACTIONS
	actions: {
		create: {
			modalTitle: 'Crear Acción'
		},
		edit: {
			modalTitle: 'Editar Acción',
			successAlert: 'Acción actualizada correctamente',
		},
		delete: {
			confirmTitle: 'Eliminar Acción',
			confirmText: '¿Estás seguro de que quieres eliminar esta acción?',
			successAlert: 'Acción eliminada correctamente',
			errorAlert: 'Error al eliminar la acción, intenta de nuevo mas tarde'
		},
		types: {
			title: 'Tipos',
			hasHaptics: 'Indica que el tipo de acción tiene haptics.',
			create: {
				modalTitle: 'Crear Tipo'
			},
			edit: {
				modalTitle: 'Editar Tipo',
				successAlert: 'Tipo actualizado correctamente'
			},
			delete: {
				confirmTitle: 'Eliminar Tipo',
				confirmText: '¿Estás seguro de que quieres eliminar este tipo?',
				successAlert: 'Tipo eliminado correctamente',
				errorAlert: 'Error al eliminar el tipo, intenta de nuevo mas tarde'
			}
		},
		haptics: {
			title: 'Haptics',
			create: {
				modalTitle: 'Crear Haptic'
			},
			edit: {
				modalTitle: 'Editar Haptic',
				successAlert: 'Haptic actualizado correctamente'
			},
			delete: {
				confirmTitle: 'Eliminar Haptic',
				confirmText: '¿Estás seguro de que quieres eliminar este haptic?',
				successAlert: 'Haptic eliminado correctamente',
				errorAlert: 'Error al eliminar el haptic, intenta de nuevo mas tarde'
			}
		}
	},

	// USERS
	users: {
		create: {
			modalTitle: 'Crear Usuario'
		},
		edit: {
			modalTitle: 'Editar Usuario',
			successAlert: 'Usuario actualizado correctamente',
		},
		editPassword: {
			successAlert: 'Contraseña actualizada correctamente',
			errorAlert: 'Error al actualizar la contraseña, intenta de nuevo mas tarde',
		},
		delete: {
			confirmTitle: 'Eliminar Usuario',
			confirmText: '¿Estás seguro de que quieres eliminar este usuario?',
			successAlert: 'Usuario eliminado correctamente',
			errorAlert: 'Error al eliminar el usuario, intenta de nuevo mas tarde'
		},
		details: {
			title: 'Detalles de Usuario',
			progress: 'Progreso',
			difficultyError: 'No existen dificultades disponibles para asignar al usuario.',
			unlockSuccess: 'Receta desbloqueada correctamente',
			unlockError: 'Error al desbloquear la receta, intenta de nuevo mas tarde'
		}
	},

	// BUTTONS
	buttons: {
		add: 'Agregar',
		all: 'Todo',
		cancel: 'Cancelar',
		confirm: 'Confirmar',
		continue: 'Continuar',
		create: 'Crear',
		delete: 'Eliminar',
		details: 'Detalles',
		edit: 'Editar',
		finish: 'Terminar',
		reorder: 'Reordenar',
		reset: 'Resetear',
		save: 'Guardar',
		search: 'Buscar',
		upload: 'Cargar'
	},

	// FORM
	form: {
		label: {
			name: 'Nombre',
			description: 'Descripción',
			image: 'Imagen',
			nationality: 'Nacionalidad',
			color: 'Color',
			world: 'Mundo',
			video: 'Video',
			startVideo: 'Inicio del video',
			endVideo: 'Fin del video',
			ingredient: 'Ingrediente',
			initialState: 'Estado Inicial',
			finalState: 'Estado Final',
			quantity: 'Cant.',
			unit: 'Unidad',
			price: 'Precio',
			pdf: 'Archivo PDF',
			scale: 'Escala',
			texture: 'Textura',
			lightning: 'Iluminación',
			abbreviation: 'Abreviatura',
			language: 'Idioma',
			first_name: 'Nombre(s)',
			last_name: 'Apellido(s)',
			email: 'Correo Electrónico',
			username: 'Nombre de Usuario',
			password: 'Contraseña',
			confirm: 'Confirmar Contraseña',
			sprite: 'Hoja de Sprites',
			recipe_type: 'Tipo de Paso',
			instruction: 'Instrucción',
			state: 'Estado',
			action: 'Acción',
			actionType: 'Tipo de Acción',
			equipment: 'Equipo de Cocina',
			hapticType: 'Tipo de Haptic',
			element: 'Elemento',
			destiny: 'Destino',
			screenPercentage: 'Porcentaje de Pantalla',
			nPointers: 'Número de Punteros',
			mirrored: 'Reflejado',
			begin: 'Inicio',
			end: 'Fin',
			speedRate: 'Velocidad',
			holdTime: 'Tiempo de Presión',
			magnitude: 'Magnitud',
			direction: 'Dirección',
			angle: 'Ángulo',
			repeats: 'Repeticiones',
			rotateDirection: 'Dirección de Rotación',
			repeats: 'Repeticiones',
			itemType: 'Tipo de Utensilio',
			resultGoesToBowl: 'Mover Resultado',
			medalType: 'Tipo de Pin',
			medalValue: 'Valor de Pin',
			optimalValue: 'Valor Óptimo',
			offset: 'Offset',
			points: 'Puntos',
			challengeValue: 'Valor de Desafío',
			challengeType: 'Tipo de Desafío',
			hasProductAsset: 'Imagen de Producto',
			matter: 'Edo. de la Materia',
			itemUsage: 'Uso de Utensilio',
			itemContentScale: 'Escala de Contenido',
			type: 'Tipo',
			category: 'Categoría',
			particles: 'Partículas',
			purchaseLevelConfig: 'Compra requerida',
			content: 'Contenido',
		},
		placeholders: {
			name: 'Ingresa nombre',
			description: 'Ingresa descripción',
			nationality: 'Ingresa nacionalidad',
			color: 'Ingresa color',
			world: 'Selecciona mundo',
			video: 'Ingresa link de video',
			videoTime: 'Tiempo en segundos',
			ingredient: 'Selecciona ingrediente',
			quantity: 'Ingresa cantidad',
			unit: 'Selecciona unidad',
			price: 'Ingresa precio',
			pdf: 'Click para Cargar',
			scale: 'Ingresa escala',
			searchIngredient: 'Buscar Ingrediente',
			texture: 'Ingresa textura',
			lightning: 'Ingresa iluminación',
			searchItem: 'Buscar Utensilio',
			abbreviation: 'Ingresa abreviatura',
			language: 'Selecciona idioma',
			first_name: 'Ingresa nombre(s)',
			last_name: 'Ingresa apellido(s)',
			email: 'Ingresa correo electrónico',
			username: 'Ingresa nombre de usuario',
			password: 'Ingresa contraseña',
			confirm: 'Confirma contraseña',
			recipe_type: 'Selecciona tipo de paso',
			instruction: 'Ingresa instrucción',
			state: 'Selecciona estado',
			action: 'Selecciona acción',
			actionType: 'Selecciona tipo de acción',
			cookware: 'Selecciona utensilios',
			equipment: 'Ingresa equipo de cocina',
			select: 'Selecciona',
			enter: 'Ingresa',
			challengeValue: {
				time: 'Porcentaje de tiempo',
				haptic: 'Porcentaje de precisión',
				value: 'Valor (temperatura, velocidad, etc.)',
				order: 'Orden'
			}
		},
		rules: {
			required: 'Este campo es obligatorio',
			url: 'Ingresa un link válido',
			pdf: 'no es un archivo PDF, por favor sube un archivo PDF.',
			email: 'Ingresa un correo electrónico válido',
			passwordMatch: 'Las contraseñas no coinciden',
			hapticSliceNumPointers: 'El número debe ser 1 ó 2',
			fillableItem: 'Solo puede haber un utensilio que sea rellenable (R)',
			holdableItem: 'Solo puede haber un utensilio que sea sostenible (S)'
		},
		update: {
			noChangesAlert: 'No se realizaron ajustes. Intenta modificar algún campo antes de guardar.'
		}
	},

	// TABLES
	table: {
		headers: {
			actions: 'Acciones'
		},
		pagination: {
			of: 'de',
			page: 'Página'
		},
		error: {
			noData: 'No hay datos'
		},
		copyableText: {
			copy: 'Copiar',
			copied: 'Copiado',
			copyId: 'Copiar ID',
			copiedId: 'ID Copiado'
		}
	},

	// STATUS / TYPES DESCRIPTIONS
	statusDesc: {
		status: {
			available: 'Disponible',
			blocked: 'Bloqueado',
			comingSoon: 'Próximamente',
			disabled: 'Deshabilitado',
			played: 'Jugado',
		},
		bool: {
			true: 'Verdadero',
			false: 'Falso'
		},
		stepTypes: {
			empty: 'Juego',
			both: 'Juego y contenido',
			content: 'Contenido'
		},
		haptictypes: {
			drag: 'Arrastrar',
			slice: 'Cortar',
			tap: 'Tocar',
			shake: 'Agitar',
			directedMove: 'Movimiento Dirigido',
			rotate: 'Rotar',
			freeze: 'Congelar',
			interactWithOverlay: 'Interactuar con Equipo'
		},
		hapticDragElementTypes: {
			ingredient: 'Ingrediente',
			item: 'Elemento'
		},
		hapticAlignments: {
			topLeft: 'Arriba Izquierda',
			topCenter: 'Arriba Centro',
			topRight: 'Arriba Derecha',
			centerLeft: 'Centro Izquierda',
			center: 'Centro',
			centerRight: 'Centro Derecha',
			bottomLeft: 'Abajo Izquierda',
			bottomCenter: 'Abajo Centro',
			bottomRight: 'Abajo Derecha'
		},
		hapticMagnitudes: {
			low: 'Baja',
			normal: 'Normal',
			hard: 'Fuerte'
		},
		hapticSpeedRates: {
			slow: 'Lenta',
			medium: 'Media',
			fast: 'Rápida'
		},
		hapticsAxis: {
			horizontal: 'Horizontal',
			vertical: 'Vertical'
		},
		hapticsAxisDirections: {
			up: 'Arriba',
			right: 'Derecha',
			down: 'Abajo',
			left: 'Izquierda'
		},
		itemTypes: {
			fillable: 'Rellenable',
			holdable: 'Sostenible',
		},
		equipmentOptions: {
			stove: 'Estufa',
			oven: 'Horno',
			microwave: 'Microondas',
			fridge: 'Refrigerador',
			dishwasher: 'Lavavajillas',
			table: 'Mesa',
			cuttingTable: 'Mesa de Corte',
			bowl: 'Tazón',
			tray: 'Charola',
			plate: 'Plato',
		},
		medalTypes: {
			time: 'Tiempo',
			world: 'Mundo',
			challenge: 'Desafío',
			recipe: 'Receta',
			score: 'Puntuación'
		},
		challengeTypes: {
			time: 'Tiempo',
			haptic: 'Precisión',
			value: 'Valor',
			order: 'Orden'
		},
		propertiesMatter: {
			solid: 'Sólido',
			liquid: 'Líquido',
			gas: 'Gas',
			other: 'Otro'
		},
		itemUsage: {
			top: 'Superior',
			glass: 'Vaso',
		},
		ingredientType: {
			normal: 'Normal',
			compound: 'Compuesto',
		}
	}	
};
