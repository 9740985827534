import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Packages
import { Button, Descriptions, Divider, Flex, Space, Typography } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import RecipesIngredientsForm from './RecipesIngredientsForm';

// Actions
import { get_all_recipe_ingredients, recipe_ingredient_delete } from '../../../redux/actions/recipesActions';

const RecipeIngredients = (props) => {
	const { messageApi, recipeId, showFinishButton } = props;

	const { t } = useTranslation();

	const navigate = useNavigate();

	const dispatch = useDispatch();

	const { all_recipe_ingredients } = useSelector(state => state.recipes);

	const [showForm, setShowForm] = useState(false);
	const [update, setUpdate] = useState(false);
	const [recipeIngredientToUpdate, setRecipeIngredientToUpdate] = useState({});

	useEffect(() => {
		dispatch(get_all_recipe_ingredients(recipeId));
	}, []);

	const addRecipeIngredient = () => {
		setShowForm(true);
		setUpdate(false);
		setRecipeIngredientToUpdate({});
	}

	const updateRecipeIngredient = (recipeIngredient) => {
		setUpdate(true);
		setRecipeIngredientToUpdate(recipeIngredient);
	}

	const deleteRecipeIngredient = (ingredientId) => {
		dispatch(recipe_ingredient_delete(messageApi, recipeId, ingredientId));
	}

	return (
		<div>
			<Divider />
			<Typography.Title level={4} style={{ textAlign: 'center', margin: '2rem' }}>{t('recipes.ingredients.addIngredients')}</Typography.Title>

			{all_recipe_ingredients.length > 0 &&
				all_recipe_ingredients.map((recipeIngredient, index) => (
					<div key={recipeIngredient?._id?.$oid}>
						{recipeIngredientToUpdate?._id?.$oid === recipeIngredient?._id?.$oid
							?	<RecipesIngredientsForm
									messageApi={messageApi}
									recipeId={recipeId}
									setShowForm={setShowForm}
									update={update}
									setUpdate={setUpdate}
									recipeIngredientToUpdate={recipeIngredientToUpdate}
									setRecipeIngredientToUpdate={setRecipeIngredientToUpdate}
								/>
							:	<Descriptions
									key={index}
									column={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 5, xxl: 5 }}
									items={[
										{
											label: t('form.label.ingredient'),
											children: recipeIngredient?.ingredient?.name
										},
										{
											label: t('form.label.quantity'),
											children: recipeIngredient?.properties?.quantity
										},
										{
											label: t('form.label.unit'),
											children: recipeIngredient?.properties?.unit?.name
										},
										{
											label: t('form.label.price'),
											children: recipeIngredient?.price
										},
										{
											children: (
												<Space>
													<Button
														type='primary'
														className='btn-edit'
														shape='circle'
														icon={<EditOutlined />}
														onClick={() => updateRecipeIngredient(recipeIngredient)}
													/>
													<Button
														type='primary'
														className='btn-cancel'
														shape='circle'
														icon={<DeleteOutlined />}
														onClick={() => deleteRecipeIngredient(recipeIngredient?._id?.$oid)}
													/>
												</Space>
											)
										}
									]}
								/>
						}
					</div>
				))
			}

			{showForm &&
				<RecipesIngredientsForm
					messageApi={messageApi}
					recipeId={recipeId}
					setShowForm={setShowForm}
					update={update}
					setUpdate={setUpdate}
					recipeIngredientToUpdate={recipeIngredientToUpdate}
					setRecipeIngredientToUpdate={setRecipeIngredientToUpdate}
				/>
			}

			<Flex justify='center' style={{ marginBottom: '1rem' }}>
				<Button
					type='default'
					block
					icon={<PlusOutlined />}
					onClick={addRecipeIngredient}
				>
					{t('buttons.add')}
				</Button>
			</Flex>
			
			{showFinishButton &&
				<Button
					type='primary'
					htmlType='submit'
					block
					disabled={all_recipe_ingredients.length === 0}
					onClick={() => navigate(`/recipes/${recipeId}/details`)}
				>
					{t('buttons.finish')}
				</Button>
			}
		</div>
	)
}

RecipeIngredients.propTypes = {
	messageApi: PropTypes.object.isRequired,
	recipeId: PropTypes.string.isRequired,
	showFinishButton: PropTypes.bool.isRequired
}

export default RecipeIngredients;
