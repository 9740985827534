import Axios from 'axios';
import i18next from 'i18next';

import {
	CLEAR_ALL_RECIPES_DATA,
	CLEAR_ALL_RECIPES_ERRORS,
	CLEAR_RECIPES_ERROR,
	SET_RECIPE_ID,
	RECIPE_CREATE_LOADING,
	RECIPE_CREATE_ERROR,
	ALL_RECIPES_LOADING,
	ALL_RECIPES_GET,
	ALL_RECIPES_ERROR,
	RECIPE_DATA_LOADING,
	RECIPE_DATA_GET,
	RECIPE_DATA_ERROR,
	WORLD_INFO_GET,
	RECIPE_UPDATE_ERROR,
	RECIPE_DELETE_LOADING,
	RECIPE_INGREDIENT_CREATE_LOADING,
	RECIPE_INGREDIENT_CREATE_ERROR,
	ALL_RECIPE_INGREDIENTS_LOADING,
	ALL_RECIPE_INGREDIENTS_GET,
	ALL_RECIPE_INGREDIENTS_ERROR,
	RECIPE_INGREDIENT_DELETE_LOADING,
	RECIPE_GAME_DATA_LOADING,
	RECIPE_GAME_DATA_GET,
	RECIPE_GAME_DATA_ERROR,
	UNLOCK_USER_RECIPE_LOADING,
	UNLOCK_USER_RECIPE_SUCCESS,
	UNLOCK_USER_RECIPE_ERROR
} from '../types';

// Utils
import create_query_params from '../../utils/create_query_params';
import add_key_prop from '../../utils/add_key_prop';

export const clear_all_recipes_data = () => dispatch => {
	dispatch({
		type: CLEAR_ALL_RECIPES_DATA
	});
}

export const clear_recipes_errors = (key) => dispatch => {
	if (key) {
		dispatch({
			type: CLEAR_RECIPES_ERROR,
			payload: key
		});
	}
	else {
		dispatch({
			type: CLEAR_ALL_RECIPES_ERRORS
		});
	}
}

export const set_recipe_create_id = (recipeId) => dispatch => {
	dispatch({
		type: SET_RECIPE_ID,
		payload: recipeId
	});
}

export const recipe_create = (messageApi, recipeBody) => dispatch => {
	dispatch(clear_recipes_errors('recipe_create'));

	dispatch({
		type: RECIPE_CREATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/recipes/create';

	Axios.post(url, recipeBody)
	.then((res) => {
		// console.log(res.data);
		dispatch(set_recipe_create_id(res.data.recipe));

		messageApi.success(i18next.t('recipes.create.successAlert'), 4);

		dispatch({
			type: RECIPE_CREATE_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: RECIPE_CREATE_ERROR,
			payload: { type: 'recipe_create', msg: err.message }
		});

		dispatch({
			type: RECIPE_CREATE_LOADING,
			payload: false
		});
	})
}

export const recipe_tutorial_upload = (options, reload) => dispatch => {
	const { file, onSuccess, onError, action, onProgress } = options;
	
	onProgress({ percent: 0 }, file);
	
	const formData = new FormData();
	formData.append('file', file);

	Axios.post(action, formData, {
		headers: { 'Content-Type': 'multipart/form-data' }
	})
	.then((res) => {
		// console.log(res.data);
		onSuccess(res.data, file);

		if (reload) {
			window.location.reload();
		}
	}).catch((err) => {
		// console.log(err);
		onError(err);
	})
}

export const get_all_recipes = (filters) => dispatch => {
	dispatch({
		type: ALL_RECIPES_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/recipes/multiple?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		const newRecipesArray = add_key_prop(res.data.recipes);
		res.data.recipes = newRecipesArray;

		dispatch({
			type: ALL_RECIPES_GET,
			payload: res.data
		});

		dispatch({
			type: ALL_RECIPES_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ALL_RECIPES_ERROR,
			payload: { type: 'get_all_recipes', msg: err.message }
		});

		dispatch({
			type: ALL_RECIPES_LOADING,
			payload: false
		});
	})
}

export const get_recipe_data = (recipeId) => dispatch => {
	dispatch({
		type: RECIPE_DATA_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/recipes/${recipeId}/data`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		dispatch({
			type: RECIPE_DATA_GET,
			payload: res.data
		});

		dispatch({
			type: RECIPE_DATA_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: RECIPE_DATA_ERROR,
			payload: { type: 'get_recipe_data', msg: err.message }
		});

		dispatch({
			type: RECIPE_DATA_LOADING,
			payload: false
		});
	})
}

export const recipe_update = (messageApi, recipeId, recipeBody) => dispatch => {
	dispatch({
		type: WORLD_INFO_GET,
		payload: {}
	});

	dispatch({
		type: RECIPE_DATA_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/recipes/${recipeId}/update`;

	Axios.put(url, recipeBody)
	.then((res) => {
		messageApi.success(i18next.t('recipes.edit.successAlert'), 4);

		dispatch({
			type: RECIPE_DATA_LOADING,
			payload: false
		});

		dispatch(get_recipe_data(recipeId));
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: RECIPE_UPDATE_ERROR,
			payload: { type: 'recipe_update', msg: err.message }
		});

		dispatch({
			type: RECIPE_DATA_LOADING,
			payload: false
		});

		dispatch(get_recipe_data(recipeId));
	})
}

export const recipe_status_update = (messageApi, recipeId, status) => dispatch => {
	let url = process.env.REACT_APP_SERVER_URL + `/api/recipes/${recipeId}/status/update`;

	Axios.put(url, { status });
}

export const recipe_delete = (messageApi, recipeId, filters) => dispatch => {
	dispatch({
		type: RECIPE_DELETE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/recipes/${recipeId}/remove`;

	Axios.delete(url)
	.then((res) => {
		messageApi.success(i18next.t('recipes.delete.successAlert'), 4);

		dispatch({
			type: RECIPE_DELETE_LOADING,
			payload: false
		});

		dispatch(get_all_recipes(filters));
	}).catch((err) => {
		// console.log(err);
		messageApi.error(i18next.t('recipes.delete.errorAlert'), 4);

		dispatch({
			type: RECIPE_DELETE_LOADING,
			payload: false
		});
	})
}

export const recipe_ingredient_create = (messageApi, recipeId, ingredientBody, handleCancel) => dispatch => {
	dispatch({
		type: RECIPE_INGREDIENT_CREATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/recipes/${recipeId}/ingredients/create`;

	Axios.post(url, ingredientBody)
	.then((res) => {
		// console.log(res.data);
		messageApi.success('Ingrediente agregado correctamente', 4);

		dispatch({
			type: RECIPE_INGREDIENT_CREATE_LOADING,
			payload: false
		});

		handleCancel();
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: RECIPE_INGREDIENT_CREATE_ERROR,
			payload: { type: 'recipe_ingredient_create', msg: err.message }
		});

		dispatch({
			type: RECIPE_INGREDIENT_CREATE_LOADING,
			payload: false
		});
	})
}

export const get_all_recipe_ingredients = (recipeId) => dispatch => {
	dispatch({
		type: ALL_RECIPE_INGREDIENTS_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/recipes/${recipeId}/ingredients`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		dispatch({
			type: ALL_RECIPE_INGREDIENTS_GET,
			payload: res.data
		});

		dispatch({
			type: ALL_RECIPE_INGREDIENTS_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ALL_RECIPE_INGREDIENTS_ERROR,
			payload: { type: 'get_all_recipe_ingredients', msg: err.message }
		});

		dispatch({
			type: ALL_RECIPE_INGREDIENTS_LOADING,
			payload: false
		});
	})
}

export const recipe_ingredient_update = (messageApi, recipeId, ingredientBody, handleCancel) => dispatch => {
	dispatch({
		type: RECIPE_INGREDIENT_CREATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/recipes/${recipeId}/ingredients/update`;

	Axios.post(url, ingredientBody)
	.then((res) => {
		// console.log(res.data);
		messageApi.success('Ingrediente actualizado correctamente', 4);

		dispatch({
			type: RECIPE_INGREDIENT_CREATE_LOADING,
			payload: false
		});

		handleCancel();
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: RECIPE_INGREDIENT_CREATE_ERROR,
			payload: { type: 'recipe_ingredient_update', msg: err.message }
		});

		dispatch({
			type: RECIPE_INGREDIENT_CREATE_LOADING,
			payload: false
		});
	})
}

export const recipe_ingredient_delete = (messageApi, recipeId, ingredientId) => dispatch => {
	dispatch({
		type: RECIPE_INGREDIENT_DELETE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/recipes/${recipeId}/ingredients/remove?ingredient=${ingredientId}`;

	Axios.delete(url)
	.then((res) => {
		// console.log(res.data);
		messageApi.success('Ingrediente eliminado correctamente', 4);

		dispatch({
			type: RECIPE_INGREDIENT_DELETE_LOADING,
			payload: false
		});

		dispatch(get_all_recipe_ingredients(recipeId));
	}).catch((err) => {
		// console.log(err);
		messageApi.error('Error al eliminar el ingrediente, intenta de nuevo mas tarde', 4);

		dispatch({
			type: RECIPE_INGREDIENT_DELETE_LOADING,
			payload: false
		});
	})
}

export const get_recipe_game_data = (filters) => dispatch => {
	dispatch({
		type: RECIPE_GAME_DATA_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/game/recipes/data?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		dispatch({
			type: RECIPE_GAME_DATA_GET,
			payload: res.data
		});

		dispatch({
			type: RECIPE_GAME_DATA_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: RECIPE_GAME_DATA_ERROR,
			payload: { type: 'get_recipe_game_data', msg: err.message }
		});

		dispatch({
			type: RECIPE_GAME_DATA_LOADING,
			payload: false
		});
	})
}

export const unlock_user_recipe = (messageApi, body) => dispatch => {
	dispatch({
		type: UNLOCK_USER_RECIPE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/game/recipes/unlock';

	Axios.post(url, body)
	.then((res) => {
		// console.log(res.data);
		dispatch({
			type: UNLOCK_USER_RECIPE_SUCCESS,
			payload: res.data
		});

		messageApi.success(i18next.t('users.details.unlockSuccess'));
		dispatch(get_recipe_game_data({ user: body.user }));

		dispatch({
			type: UNLOCK_USER_RECIPE_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: UNLOCK_USER_RECIPE_ERROR,
			payload: { type: 'unlock_user_recipe', msg: err.message }
		});

		messageApi.error(i18next.t('users.details.unlockError'));

		dispatch({
			type: UNLOCK_USER_RECIPE_LOADING,
			payload: false
		});
	})
}
