import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Alert, Button, Form, Input, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { sha256 } from 'js-sha256';
import PropTypes from 'prop-types';

// Actions
import { get_all_users, user_create, user_update } from '../../../redux/actions/usersActions';

// Utils
import isEmpty from '../../../utils/isEmpty';

const UsersForm = (props) => {
	const { messageApi, isModalOpen, setIsModalOpen, update, userToUpdate, setUserToUpdate, filters } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { user_create_loading, user_update_loading, users_errors } = useSelector(state => state.users);
	
	const [form] = Form.useForm();

	useEffect(() => {
		if (!isEmpty(userToUpdate)) {
			form.setFieldsValue(userToUpdate);
		}
	}, [userToUpdate]);

	const handleCancel = (finish) => {
		form.resetFields();
		setIsModalOpen(false);
		setUserToUpdate({});
		// dispatch(clear_users_errors('user_update'));

		if (finish) {
			dispatch(get_all_users(filters));
		}
	}

	const onOk = () => {
		form
			.validateFields()
			.then((values) => {
				if (!update) {
					// Create
					values.user_type = 1;
					values.password = sha256(values.password);
					values.confirm = sha256(values.confirm);

					dispatch(user_create(values, () => handleCancel(true)));
				}
				else {
					// Update
					dispatch(user_update(messageApi, userToUpdate?._id?.$oid, values, () => handleCancel(true)));
				}
			})
			.catch((info) => {
				console.error(info);
			});
	}

	return (
		<Modal
			title={!update ? t('users.create.modalTitle') : t('users.edit.modalTitle')}
			centered
			open={isModalOpen}
			onCancel={() => handleCancel(false)}
			maskClosable={false}
			width={700}
			footer={[
				<Button
					key='submit'
					type='primary'
					loading={!update ? user_create_loading : user_update_loading}
					onClick={onOk}
				>
					{update
						?	t('buttons.edit')
						:	t('buttons.create')
					}
				</Button>,
			]}
		>
			{users_errors.hasOwnProperty('user_update') &&
				<Alert
					style={{ textAlign: 'start', marginBottom: '1.5rem' }}
					message={users_errors.user_update.msg}
					type='error'
					showIcon
				/>
			}
			<Form
				labelCol={{ span: 6 }}
				form={form}
			>
				<Form.Item
					label={t('form.label.first_name')}
					name='first_name'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						}
					]}
				>
					<Input placeholder={t('form.placeholders.first_name')} />
				</Form.Item>
				<Form.Item
					label={t('form.label.last_name')}
					name='last_name'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						}
					]}
				>
					<Input placeholder={t('form.placeholders.last_name')} />
				</Form.Item>
				<Form.Item
					label={t('form.label.email')}
					name='email'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						},
						{
							type: 'email',
							message: t('form.rules.email')
						}
					]}
				>
					<Input placeholder={t('form.placeholders.email')} />
				</Form.Item>
				<Form.Item
					label={t('form.label.username')}
					name='username'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						}
					]}
				>
					<Input placeholder={t('form.placeholders.username')} />
				</Form.Item>
				{!update &&
					<div>
						<Form.Item
							label={t('form.label.password')}
							name='password'
							rules={[
								{
									required: true,
									message: t('form.rules.required'),
								},
							]}
							hasFeedback
						>
							<Input.Password placeholder={t('form.placeholders.password')} />
						</Form.Item>
						<Form.Item
							label={t('form.label.confirm')}
							name='confirm'
							dependencies={['password']}
							hasFeedback
							rules={[
								{
									required: true,
									message: t('form.rules.required'),
								},
								({ getFieldValue }) => ({
									validator(_, value) {
										if (!value || getFieldValue('password') === value) {
											return Promise.resolve();
										}
										return Promise.reject(new Error(t('form.rules.passwordMatch')));
									},
								}),
							]}
						>
							<Input.Password placeholder={t('form.placeholders.confirm')} />
						</Form.Item>
					</div>
				}
			</Form>
		</Modal>
	)
}

UsersForm.propTypes = {
	messageApi: PropTypes.object.isRequired,
	isModalOpen: PropTypes.bool.isRequired,
	setIsModalOpen: PropTypes.func.isRequired,
	update: PropTypes.bool.isRequired,
	userToUpdate: PropTypes.object.isRequired,
	setUserToUpdate: PropTypes.func.isRequired,
	filters: PropTypes.object.isRequired
}

export default UsersForm;
