import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Alert, Button, Checkbox, Divider, Form, Modal, Popconfirm, Select } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Actions
import { get_all_worlds } from '../../../redux/actions/worldsActions';
import { get_all_recipes } from '../../../redux/actions/recipesActions';
import { clear_levels_errors, get_all_levels, level_create, level_delete, level_update } from '../../../redux/actions/levelsActions';

// Utils
import isEmpty from '../../../utils/isEmpty';
import { level_status_options } from '../../../utils/select_options';
import { create_select_options, create_select_options_from_array } from '../../../utils/create_select_options';

const LevelConfigForm = (props) => {
	const { messageApi, recipeData, isModalOpen, setIsModalOpen, update, levelConfigToUpdate, setLevelConfigToUpdate } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { all_worlds_loading, all_worlds } = useSelector(state => state.worlds);

	const { all_recipes_loading, all_recipes } = useSelector(state => state.recipes);

	const { level_create_loading, level_update_loading, level_delete_loading, levels_errors } = useSelector(state => state.levels);
	
	const [levelId, setLevelId] = useState(null);
	const [levelStatusOptions, setLevelStatusOptions] = useState([]);

	const LEVEL_STATUS_OPTIONS = level_status_options();
	
	const [form] = Form.useForm();
	
	useEffect(() => {
		setLevelStatusOptions(create_select_options_from_array(LEVEL_STATUS_OPTIONS));

		const filters = { skip: 0, limit: 0 };
		dispatch(get_all_worlds(filters));
	}, []);	

	useEffect(() => {
		if (!isEmpty(levelConfigToUpdate)) {
			setLevelId(levelConfigToUpdate._id.$oid);

			form.setFieldsValue({
				world: levelConfigToUpdate.unlock.world.$oid,
				unlock: levelConfigToUpdate.unlock._id.$oid,
				status: levelConfigToUpdate.status,
				purchase: levelConfigToUpdate.purchase
			});

			dispatch(get_all_recipes({ world: levelConfigToUpdate.unlock.world.$oid }));
		}
	}, [levelConfigToUpdate]);

	const handleCancel = (finish) => {
		form.resetFields();
		setIsModalOpen(false);
		setLevelConfigToUpdate({});
		dispatch(clear_levels_errors());
		setLevelId(null);

		if (finish) {
			dispatch(get_all_levels({ recipe: recipeData?.recipe?._id?.$oid }));
		}
	}

	const onValuesChange = (changedValues, allValues) => {
		if (changedValues.world) {
			form.setFieldsValue({ unlock: undefined });
			dispatch(get_all_recipes({ world: changedValues.world }));
		}
	} 

	const onOk = () => {
		form
			.validateFields()
			.then((values) => {
				const levelBody = {
					recipe: recipeData?.recipe?._id?.$oid,
					world: recipeData?.recipe?.world?.$oid,
					status: values.status,
					unlock: values.unlock,
					purchase: values.purchase
				}

				if (!update) {
					// Create
					dispatch(level_create(messageApi, levelBody, () => handleCancel(true)));
				}
				else {
					// Update
					dispatch(level_update(messageApi, levelId, levelBody, () => handleCancel(true)));
				}
			})
			.catch((info) => {
				console.error(info);
			});
	}

	return (
		<Modal
			title={t('levels.modalTitle')}
			centered
			open={isModalOpen}
			onCancel={() => handleCancel(false)}
			maskClosable={false}
			width={700}
			footer={[
				update &&
					<Popconfirm
						title={t('levels.delete.confirmTitle')}
						description={t('levels.delete.confirmText')}
						icon={<QuestionCircleFilled style={{ color: '#1677FF' }} />}
						onConfirm={() => dispatch(level_delete(messageApi, levelId, form, () => handleCancel(true)))}
						okButtonProps={{
							loading: level_delete_loading
						}}
						okText={t('buttons.confirm')}
						cancelText={t('buttons.cancel')}
					>
						<Button htmlType='button'>
							{t('buttons.delete')}
						</Button>
					</Popconfirm>
				,
				<Button
					key='submit'
					type='primary'
					loading={!update ? level_create_loading : level_update_loading}
					onClick={levelId && !update ? () => handleCancel(true) : onOk}
				>
					{update
						?	t('buttons.edit')
						:	t('buttons.create')
					}
				</Button>
			]}
		>
			<Form
				labelCol={{ span: 6 }}
				form={form}
				onValuesChange={onValuesChange}
				initialValues={{ purchase: false }}
			>
				{levels_errors.hasOwnProperty('level_create') &&
					<Alert
						style={{ textAlign: 'start', marginBottom: '1.5rem' }}
						message={t('levels.create.errorAlert')}
						type='error'
						showIcon
					/>
				}
				{levels_errors.hasOwnProperty('level_update') && (levels_errors.level_update.msg.response.status === 400 && levels_errors.level_update.msg.response.data.error !== undefined
					?	<Alert
							style={{ textAlign: 'start', marginBottom: '1.5rem' }}
							message={t('form.update.noChangesAlert')}
							type='info'
							showIcon
						/>
					:	<Alert
							style={{ textAlign: 'start', marginBottom: '1.5rem' }}
							message={t('levels.edit.errorAlert')}
							type='error'
							showIcon
						/>
				)}
				<Form.Item
					label={`${t('form.label.state')} ${t('levels.initial')}`}
					name='status'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						}
					]}
				>
					<Select
						showSearch
						placeholder={`${t('form.placeholders.select')} ${t('form.label.state')} ${t('levels.initial')}`}
						optionFilterProp='children'
						filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
						options={levelStatusOptions}
					/>
				</Form.Item>

				<Divider style={{ fontWeight: 'bold' }} orientation='left' plain>{t('levels.toUnlock')}</Divider>

				<Form.Item
					label={t('form.label.world')}
					name='world'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						}
					]}
				>
					<Select
						showSearch
						loading={all_worlds_loading}
						placeholder={`${t('form.placeholders.select')} ${t('form.label.world')}`}
						optionFilterProp='children'
						filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
						options={create_select_options(all_worlds.worlds, 'name')}
					/>
				</Form.Item>
				<Form.Item
					label={t('menuItems.recipes').slice(0, -1)}
					name='unlock'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						}
					]}
				>
					<Select
						showSearch
						loading={all_recipes_loading}
						placeholder={`${t('form.placeholders.select')} ${t('menuItems.recipes').slice(0, -1)}`}
						optionFilterProp='children'
						filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
						options={create_select_options(all_recipes.recipes.filter(recipe => recipe?._id?.$oid !== recipeData?.recipe?._id?.$oid), 'name')}
					/>
				</Form.Item>
				<Form.Item
					label={t('form.label.purchaseLevelConfig')}
					name='purchase'
					valuePropName='checked'
				>
					<Checkbox />
				</Form.Item>
			</Form>
		</Modal>
	)
}

LevelConfigForm.propTypes = {
	messageApi: PropTypes.object.isRequired,
	recipeData: PropTypes.object.isRequired,
	isModalOpen: PropTypes.bool.isRequired,
	setIsModalOpen: PropTypes.func.isRequired,
	update: PropTypes.bool.isRequired,
	levelConfigToUpdate: PropTypes.object.isRequired,
	setLevelConfigToUpdate: PropTypes.func.isRequired
}

export default LevelConfigForm;
