import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Alert, Button, Checkbox, Form, Input, InputNumber, Modal, Select, Slider } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Actions
import { get_all_haptics, haptic_create, haptic_update } from '../../../redux/actions/hapticsActions';

// Utils
import isEmpty from '../../../utils/isEmpty';
import {
	haptic_types_options, haptic_drag_element_types_options, haptic_alignment_options, haptic_magnitude_options,
	haptic_speed_rate_options, haptic_axis_options, haptic_axis_direction_options
} from '../../../utils/select_options';
import { create_haptic_select_options } from '../../../utils/create_select_options';

const HapticsForm = (props) => {
	const { messageApi, typeId, isHapticModalOpen, setIsHapticModalOpen, updateHaptic, hapticToUpdate, setHapticToUpdate, filters } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { type_create_loading, type_update_loading } = useSelector(state => state.types);

	const { haptics_errors } = useSelector(state => state.haptics);

	const [hapticType, setHapticType] = useState(null);
	const [disableMirroredInput, setDisableMirroredInput] = useState(true);
	
	const HAPTIC_TYPES = haptic_types_options();
	const HAPTIC_DRAG_ELEMENT_TYPES = haptic_drag_element_types_options();
	const HAPTIC_ALIGNMENT = haptic_alignment_options();
	const HAPTIC_MAGNITUDE = haptic_magnitude_options();
	const HAPTIC_SPEED_RATE = haptic_speed_rate_options();
	const HAPTIC_AXIS = haptic_axis_options();
	const HAPTIC_AXIS_DIRECTION = haptic_axis_direction_options();
	
	const [form] = Form.useForm();

	useEffect(() => {
		if (!isEmpty(hapticToUpdate)) {
			setHapticType(hapticToUpdate.haptic_type);
			form.setFieldsValue(hapticToUpdate);
		}
	}, [hapticToUpdate]);

	const handleCancel = (finish) => {
		setIsHapticModalOpen(false);
		setHapticType(null);
		setHapticToUpdate({});
		setDisableMirroredInput(true);
		form.resetFields();

		if (finish) {
			dispatch(get_all_haptics(filters));
		}
	}

	const onValuesChange = (changedValues, allValues) => {
		if (changedValues.slice?.n_pointers) {
			const n_pointers = changedValues.slice.n_pointers;
			if (n_pointers === 2) {
				setDisableMirroredInput(false);
			}
			else {
				setDisableMirroredInput(true);
			}
		}
	}

	const onOk = () => {
		form
			.validateFields()
			.then((values) => {
				if (!updateHaptic) {
					// Create
					values.action = typeId;
					dispatch(haptic_create(values, () => handleCancel(true)));
				}
				else {
					// Update
					dispatch(haptic_update(messageApi, hapticToUpdate?._id?.$oid, values, () => handleCancel(true)));
				}
			})
			.catch((info) => {
				console.error(info);
			});
	}

	return (
		<Modal
			title={!updateHaptic ? t('actions.haptics.create.modalTitle') : t('actions.haptics.edit.modalTitle')}
			centered
			open={isHapticModalOpen}
			onCancel={() => handleCancel(false)}
			maskClosable={false}
			width={700}
			footer={[
				<Button
					key='submit'
					type='primary'
					loading={!updateHaptic ? type_create_loading : type_update_loading}
					onClick={onOk}
				>
					{updateHaptic
						?	t('buttons.edit')
						:	t('buttons.create')
					}
				</Button>,
			]}
		>
			{haptics_errors.hasOwnProperty('haptic_create') &&
				<Alert
					style={{ textAlign: 'start', marginBottom: '1.5rem' }}
					message={haptics_errors.haptic_create.msg}
					type='error'
					showIcon
				/>
			}
			{haptics_errors.hasOwnProperty('haptic_update') &&
				<Alert
					style={{ textAlign: 'start', marginBottom: '1.5rem' }}
					message={haptics_errors.haptic_update.msg}
					type='error'
					showIcon
				/>
			}
			<Form
				labelCol={{ span: 7 }}
				form={form}
				initialValues={{ repeats: 1, slice: { n_pointers: 1, mirrored: false } }}
				onValuesChange={onValuesChange}
			>
				<Form.Item
					label={t('form.label.name')}
					name='name'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						}
					]}
				>
					<Input placeholder={`${t('form.placeholders.enter')} ${t('form.label.name')}`} />
				</Form.Item>
				<Form.Item
					label={t('form.label.description')}
					name='description'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						}
					]}
				>
					<Input placeholder={`${t('form.placeholders.enter')} ${t('form.label.description')}`} />
				</Form.Item>
				<Form.Item
					label={t('form.label.hapticType')}
					name='haptic_type'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						}
					]}
				>
					<Select
						showSearch
						placeholder={`${t('form.placeholders.select')} ${t('form.label.hapticType')}`}
						optionFilterProp='children'
						filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
						options={create_haptic_select_options(HAPTIC_TYPES)}
						onChange={(value) => setHapticType(value)}
					/>
				</Form.Item>

				{/* drag */}
				{hapticType === 0 && (
					<>
						<Form.Item
							label={t('form.label.element')}
							name={['drag', 'element']}
							rules={[
								{
									required: true,
									message: t('form.rules.required'),
								}
							]}
						>
							<Select
								showSearch
								placeholder={`${t('form.placeholders.select')} ${t('form.label.element')}`}
								optionFilterProp='children'
								filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
								options={create_haptic_select_options(HAPTIC_DRAG_ELEMENT_TYPES)}
							/>
						</Form.Item>
						<Form.Item
							label={t('form.label.destiny')}
							name={['drag', 'destiny']}
							rules={[
								{
									required: true,
									message: t('form.rules.required'),
								}
							]}
						>
							<Select
								showSearch
								placeholder={`${t('form.placeholders.select')} ${t('form.label.destiny')}`}
								optionFilterProp='children'
								filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
								options={create_haptic_select_options(HAPTIC_DRAG_ELEMENT_TYPES)}
							/>
						</Form.Item>
					</>
				)}

				{/* slice */}
				{hapticType === 1 && (
					<>
						<Form.Item
							label={t('form.label.nPointers')}
							name={['slice', 'n_pointers']}
							rules={[
								{
									required: true,
									message: t('form.rules.required'),
								},
								{
									type: 'number',
									min: 1,
									max: 2,
									message: t('form.rules.hapticSliceNumPointers'),
								}
							]}
						>
							<InputNumber />
						</Form.Item>
						<Form.Item
							label={t('form.label.mirrored')}
							name={['slice', 'mirrored']}
							valuePropName='checked'
							rules={[
								{
									required: true,
									message: t('form.rules.required'),
								}
							]}
						>
							<Checkbox disabled={disableMirroredInput} />
						</Form.Item>
						<Form.Item
							label={t('form.label.begin')}
							name={['slice', 'begin']}
							rules={[
								{
									required: true,
									message: t('form.rules.required'),
								}
							]}
						>
							<Select
								showSearch
								placeholder={`${t('form.placeholders.select')} ${t('form.label.begin')}`}
								optionFilterProp='children'
								filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
								options={create_haptic_select_options(HAPTIC_ALIGNMENT)}
							/>
						</Form.Item>
						<Form.Item
							label={t('form.label.end')}
							name={['slice', 'end']}
							rules={[
								{
									required: true,
									message: t('form.rules.required'),
								}
							]}
						>
							<Select
								showSearch
								placeholder={`${t('form.placeholders.select')} ${t('form.label.end')}`}
								optionFilterProp='children'
								filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
								options={create_haptic_select_options(HAPTIC_ALIGNMENT)}
							/>
						</Form.Item>
						<Form.Item
							label={t('form.label.speedRate')}
							name={['slice', 'speed_rate']}
							rules={[
								{
									required: true,
									message: t('form.rules.required'),
								}
							]}
						>
							<Select
								showSearch
								placeholder={`${t('form.placeholders.select')} ${t('form.label.speedRate')}`}
								optionFilterProp='children'
								filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
								options={create_haptic_select_options(HAPTIC_SPEED_RATE)}
							/>
						</Form.Item>
					</>
				)}

				{/* tap */}
				{hapticType === 2 && (
					<>
						<Form.Item
							label={t('form.label.holdTime')}
							name={['tap', 'hold_time']}
							rules={[
								{
									required: true,
									message: t('form.rules.required'),
								}
							]}
						>
							<Slider min={0} max={10} step={0.1} tooltip={{ open: isHapticModalOpen }} />
						</Form.Item>
						<Form.Item
							label={t('form.label.begin')}
							name={['tap', 'begin']}
							rules={[
								{
									required: true,
									message: t('form.rules.required'),
								}
							]}
						>
							<Select
								showSearch
								placeholder={`${t('form.placeholders.select')} ${t('form.label.begin')}`}
								optionFilterProp='children'
								filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
								options={create_haptic_select_options(HAPTIC_ALIGNMENT)}
							/>
						</Form.Item>
						<Form.Item
							label={t('form.label.end')}
							name={['tap', 'end']}
							rules={[
								{
									required: true,
									message: t('form.rules.required'),
								}
							]}
						>
							<Select
								showSearch
								placeholder={`${t('form.placeholders.select')} ${t('form.label.end')}`}
								optionFilterProp='children'
								filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
								options={create_haptic_select_options(HAPTIC_ALIGNMENT)}
							/>
						</Form.Item>
					</>
				)}

				{/* shake */}
				{hapticType === 3 && (
					<>
						<Form.Item
							label={t('form.label.magnitude')}
							name={['shake', 'magnitude']}
							rules={[
								{
									required: true,
									message: t('form.rules.required'),
								}
							]}
						>
							<Select
								showSearch
								placeholder={`${t('form.placeholders.select')} ${t('form.label.magnitude')}`}
								optionFilterProp='children'
								filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
								options={create_haptic_select_options(HAPTIC_MAGNITUDE)}
							/>
						</Form.Item>
						<Form.Item
							label={t('form.label.direction')}
							name={['shake', 'direction']}
							rules={[
								{
									required: true,
									message: t('form.rules.required'),
								}
							]}
						>
							<Select
								showSearch
								placeholder={`${t('form.placeholders.select')} ${t('form.label.direction')}`}
								optionFilterProp='children'
								filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
								options={create_haptic_select_options(HAPTIC_AXIS)}
							/>
						</Form.Item>
					</>
				)}

				{/* directed_move */}
				{hapticType === 4 && (
					<>
						<Form.Item
							label={t('form.label.magnitude')}
							name={['directed_move', 'magnitude']}
							rules={[
								{
									required: true,
									message: t('form.rules.required'),
								}
							]}
						>
							<Select
								showSearch
								placeholder={`${t('form.placeholders.select')} ${t('form.label.magnitude')}`}
								optionFilterProp='children'
								filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
								options={create_haptic_select_options(HAPTIC_MAGNITUDE)}
							/>
						</Form.Item>
						<Form.Item
							label={t('form.label.direction')}
							name={['directed_move', 'direction']}
							rules={[
								{
									required: true,
									message: t('form.rules.required'),
								}
							]}
						>
							<Select
								showSearch
								placeholder={`${t('form.placeholders.select')} ${t('form.label.direction')}`}
								optionFilterProp='children'
								filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
								options={create_haptic_select_options(HAPTIC_AXIS_DIRECTION)}
							/>
						</Form.Item>
					</>
				)}

				{/* rotate */}
				{hapticType === 5 && (
					<>
						<Form.Item
							label={t('form.label.speedRate')}
							name={['rotate', 'speed_rate']}
							rules={[
								{
									required: true,
									message: t('form.rules.required'),
								}
							]}
						>
							<Select
								showSearch
								placeholder={`${t('form.placeholders.select')} ${t('form.label.speedRate')}`}
								optionFilterProp='children'
								filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
								options={create_haptic_select_options(HAPTIC_SPEED_RATE)}
							/>
						</Form.Item>
						<Form.Item
							label={t('form.label.rotateDirection')}
							name={['rotate', 'speed_rate']}
							rules={[
								{
									required: true,
									message: t('form.rules.required'),
								}
							]}
						>
							<Select
								showSearch
								placeholder={`${t('form.placeholders.select')} ${t('form.label.rotateDirection')}`}
								optionFilterProp='children'
								filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
								options={create_haptic_select_options(HAPTIC_AXIS)}
							/>
						</Form.Item>
					</>
				)}
				
				{/* repeats */}
				{hapticType !== null && (
					<Form.Item
						label={t('form.label.repeats')}
						name='repeats'
						rules={[
							{
								required: true,
								message: t('form.rules.required'),
							}
						]}
					>
						<Slider min={0} max={100} tooltip={{ open: isHapticModalOpen }} />
					</Form.Item>
				)}
			</Form>
		</Modal>
	)
}

HapticsForm.propTypes = {
	messageApi: PropTypes.object.isRequired,
	typeId: PropTypes.string.isRequired,
	isHapticModalOpen: PropTypes.bool.isRequired,
	setIsHapticModalOpen: PropTypes.func.isRequired,
	updateHaptic: PropTypes.bool.isRequired,
	hapticToUpdate: PropTypes.object.isRequired,
	setHapticToUpdate: PropTypes.func.isRequired,
	filters: PropTypes.object.isRequired
}

export default HapticsForm;
