import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Alert, Button, Form, Input, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Actions
import { clear_units_errors, get_all_units, unit_create, unit_update } from '../../redux/actions/unitsActions';

// Utils
import isEmpty from '../../utils/isEmpty';

const UnitsForm = (props) => {
	const { messageApi, isModalOpen, setIsModalOpen, update, unitToUpdate, setUnitToUpdate, filters } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { unit_create_loading, unit_update_loading, units_errors } = useSelector(state => state.units);
	
	const [form] = Form.useForm();

	useEffect(() => {
		if (!isEmpty(unitToUpdate)) {
			form.setFieldsValue(unitToUpdate);
		}
	}, [unitToUpdate]);

	const handleCancel = (finish) => {
		form.resetFields();
		setIsModalOpen(false);
		setUnitToUpdate({});
		dispatch(clear_units_errors('unit_update'));

		if (finish) {
			dispatch(get_all_units(filters));
		}
	}

	const onOk = () => {
		form
			.validateFields()
			.then((values) => {
				if (!update) {
					// Create
					dispatch(unit_create(values, () => handleCancel(true)));
				}
				else {
					// Update
					const unitBody = {
						description: values.description,
						abbreviation: values.abbreviation
					}
					dispatch(unit_update(messageApi, unitToUpdate?._id?.$oid, unitBody, () => handleCancel(true)));
				}
			})
			.catch((info) => {
				console.error(info);
			});
	}

	return (
		<Modal
			title={!update ? t('units.create.modalTitle') : t('units.edit.modalTitle')}
			centered
			open={isModalOpen}
			onCancel={() => handleCancel(false)}
			maskClosable={false}
			width={700}
			footer={[
				<Button
					key='submit'
					type='primary'
					loading={!update ? unit_create_loading : unit_update_loading}
					onClick={onOk}
				>
					{update
						?	t('buttons.edit')
						:	t('buttons.create')
					}
				</Button>,
			]}
		>
			{units_errors.hasOwnProperty('unit_update') &&
				<Alert
					style={{ textAlign: 'start', marginBottom: '1.5rem' }}
					message={units_errors.unit_update.msg}
					type='error'
					showIcon
				/>
			}
			<Form
				labelCol={{ span: 5 }}
				form={form}
			>
				<Form.Item
					label={t('form.label.name')}
					name='name'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						}
					]}
				>
					<Input placeholder={t('form.placeholders.name')} />
				</Form.Item>
				<Form.Item
					label={t('form.label.description')}
					name='description'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						}
					]}
				>
					<Input placeholder={t('form.placeholders.description')} />
				</Form.Item>
				<Form.Item
					label={t('form.label.abbreviation')}
					name='abbreviation'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						}
					]}
				>
					<Input placeholder={t('form.placeholders.abbreviation')} />
				</Form.Item>
			</Form>
		</Modal>
	)
}

UnitsForm.propTypes = {
	messageApi: PropTypes.object.isRequired,
	isModalOpen: PropTypes.bool.isRequired,
	setIsModalOpen: PropTypes.func.isRequired,
	update: PropTypes.bool.isRequired,
	unitToUpdate: PropTypes.object.isRequired,
	setUnitToUpdate: PropTypes.func.isRequired,
	filters: PropTypes.object.isRequired
}

export default UnitsForm;
