import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Button, Form, Input, InputNumber, Modal, Select, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import ImageUpload from '../../utils/ImageUpload';

// Actions
import { get_all_challenges } from '../../../redux/actions/challengesActions';
import { get_all_medals, set_medal_create_id, medal_create, medal_update } from '../../../redux/actions/medalsActions';
import { get_all_recipes } from '../../../redux/actions/recipesActions';
import { get_all_worlds } from '../../../redux/actions/worldsActions';
import { form_image_upload } from '../../../redux/actions/utilsActions';

// Utils
import isEmpty from '../../../utils/isEmpty';
import { medal_types_options } from '../../../utils/select_options';
import { create_select_options, create_select_options_from_array } from '../../../utils/create_select_options';

const MedalForm = (props) => {
	const { messageApi, isModalOpen, setIsModalOpen, update, medalToUpdate, setMedalToUpdate, pageSize, skip } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { all_challenges_loading, all_challenges } = useSelector(state => state.challenges);

	const { medal_create_loading, medal_id, medal_update_loading } = useSelector(state => state.medals);

	const { all_recipes_loading, all_recipes } = useSelector(state => state.recipes);

	const { all_worlds_loading, all_worlds } = useSelector(state => state.worlds);

	const [fileList, setFileList] = useState([]);
	const [medalTypesOptions, setMedalTypesOptions] = useState([]);
	const [medalType, setMedalType] = useState(null);

	const MEDAL_TYPES_OPTIONS = medal_types_options();
	
	const [form] = Form.useForm();

	useEffect(() => {
		setMedalTypesOptions(create_select_options_from_array(MEDAL_TYPES_OPTIONS));

		const filters = { skip: 0, limit: 0 };

		dispatch(get_all_worlds(filters));
		dispatch(get_all_recipes(filters));
		dispatch(get_all_challenges(filters));
	}, []);

	useEffect(() => {
		if (!isEmpty(medalToUpdate)) {
			form.setFieldsValue(medalToUpdate);
		}
	}, [medalToUpdate]);

	const handleCancel = (finish) => {
		form.resetFields();
		setFileList([]);
		setIsModalOpen(false);
		setMedalToUpdate({});
		setMedalType(null);
		dispatch(set_medal_create_id(null));

		if (finish) {
			const filters = { skip: skip, limit: pageSize };
			dispatch(get_all_medals(filters));
		}
	}

	const onOk = () => {
		form
			.validateFields()
			.then((values) => {
				if (!update) {
					// Create
					dispatch(medal_create(values));
				}
				else {
					// Update
					dispatch(medal_update(messageApi, medal_id, values, () => handleCancel(true)));
				}
			})
			.catch((info) => {
				console.error(info);
			});
	}

	return (
		<Modal
			title={!update ? t('medals.create.modalTitle') : t('medals.edit.modalTitle')}
			centered
			open={isModalOpen}
			onCancel={() => handleCancel(false)}
			maskClosable={false}
			width={700}
			footer={[
				<Button
					key='submit'
					type='primary'
					loading={!update ? medal_create_loading : medal_update_loading}
					onClick={medal_id && !update ? () => handleCancel(true) : onOk}
				>
					{update
						?	t('buttons.edit')
						:	(medal_id ? t('buttons.finish') : t('buttons.create'))
					}
				</Button>,
			]}
		>
			<Form
				labelCol={{ span: 5 }}
				form={form}
			>
				<Form.Item
					label={t('form.label.name')}
					name='name'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						}
					]}
				>
					<Input placeholder={t('form.placeholders.name')} />
				</Form.Item>
				<Form.Item
					label={t('form.label.description')}
					name='description'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						}
					]}
				>
					<Input placeholder={t('form.placeholders.description')} />
				</Form.Item>
				<Form.Item
					label={t('form.label.medalType')}
					name='medal_type'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						}
					]}
				>
					<Select
						showSearch
						placeholder={`${t('form.placeholders.select')} ${t('form.label.medalType')}`}
						optionFilterProp='children'
						filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
						options={medalTypesOptions}
						onChange={(value) => setMedalType(value)}
					/>
				</Form.Item>
				{medalType === 2 && (
					<Form.Item
						label={t('form.label.world')}
						name='world'
						rules={[
							{
								required: true,
								message: t('form.rules.required'),
							}
						]}
					>
						<Select
							showSearch
							loading={all_worlds_loading}
							placeholder={`${t('form.placeholders.select')} ${t('form.label.world')}`}
							optionFilterProp='children'
							filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
							options={create_select_options(all_worlds.worlds, 'name')}
						/>
					</Form.Item>
				)}
				{medalType === 3 && (
					<Form.Item
						label={t('challenges.title')}
						name='challenge'
						rules={[
							{
								required: true,
								message: t('form.rules.required'),
							}
						]}
					>
						<Select
							showSearch
							loading={all_challenges_loading}
							placeholder={`${t('form.placeholders.select')} ${t('menuItems.recipes').slice(0, -1)}`}
							optionFilterProp='children'
							filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
							options={create_select_options(all_challenges.challenges, 'name')}
						/>
					</Form.Item>
				)}
				{medalType === 4 && (
					<Form.Item
						label={t('menuItems.recipes').slice(0, -1)}
						name='recipe'
						rules={[
							{
								required: true,
								message: t('form.rules.required'),
							}
						]}
					>
						<Select
							showSearch
							loading={all_recipes_loading}
							placeholder={`${t('form.placeholders.select')} ${t('menuItems.recipes').slice(0, -1)}`}
							optionFilterProp='children'
							filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
							options={create_select_options(all_recipes.recipes, 'name')}
						/>
					</Form.Item>
				)}
				<Form.Item
					label={t('form.label.medalValue')}
					name='value'
					rules={[
						{
							required: medalType === 1 || medalType === 5,
							message: t('form.rules.required'),
						}
					]}
				>
					<InputNumber
						// step={0.1}
						style={{ width: '100%' }}
						placeholder={`${t('form.placeholders.enter')} ${t('form.label.medalValue')}`}
					/>
				</Form.Item>
			</Form>
			{medal_id && (
				<Space align='start' size={10}>
					<Typography.Text style={{ marginLeft: '4.3rem' }}>{t('form.label.image')}:</Typography.Text>
					<ImageUpload
						action={process.env.REACT_APP_SERVER_URL + `/api/game/medals/${medal_id}/asset/upload`}
						actionFunction={(options) => dispatch(form_image_upload(options))}
						fileList={fileList}
						setFileList={setFileList}
					/>
				</Space>
			)}
		</Modal>
	)
}

MedalForm.propTypes = {
	messageApi: PropTypes.object.isRequired,
	isModalOpen: PropTypes.bool.isRequired,
	setIsModalOpen: PropTypes.func.isRequired,
	update: PropTypes.bool.isRequired,
	medalToUpdate: PropTypes.object.isRequired,
	setMedalToUpdate: PropTypes.func.isRequired,
	pageSize: PropTypes.number.isRequired,
	skip: PropTypes.number.isRequired
}

export default MedalForm;
