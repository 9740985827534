import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Button, Form, Input, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Actions
import { get_all_types, type_create, type_update } from '../../../redux/actions/typesActions';

// Utils
import isEmpty from '../../../utils/isEmpty';

const TypesForm = (props) => {
	const { messageApi, actionId, isModalTypeOpen, setIsModalTypeOpen, updateType, typeToUpdate, filters } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { type_create_loading, type_update_loading } = useSelector(state => state.types);
	
	const [form] = Form.useForm();

	useEffect(() => {
		if (!isEmpty(typeToUpdate)) {
			form.setFieldsValue(typeToUpdate);
		}
	}, [typeToUpdate]);

	const handleCancel = (finish) => {
		setIsModalTypeOpen(false);
		form.resetFields();

		if (finish) {
			dispatch(get_all_types(filters));
		}
	}

	const onOk = () => {
		form
			.validateFields()
			.then((values) => {
				if (!updateType) {
					// Create
					values.action = actionId;
					dispatch(type_create(values, () => handleCancel(true)));
				}
				else {
					// Update
					dispatch(type_update(messageApi, typeToUpdate?._id?.$oid, values, () => handleCancel(true)));
				}
			})
			.catch((info) => {
				console.error(info);
			});
	}

	return (
		<Modal
			title={!updateType ? t('actions.types.create.modalTitle') : t('actions.types.edit.modalTitle')}
			centered
			open={isModalTypeOpen}
			onCancel={() => handleCancel(false)}
			maskClosable={false}
			width={700}
			footer={[
				<Button
					key='submit'
					type='primary'
					loading={!updateType ? type_create_loading : type_update_loading}
					onClick={onOk}
				>
					{updateType
						?	t('buttons.edit')
						:	t('buttons.create')
					}
				</Button>,
			]}
		>
			<Form
				labelCol={{ span: 5 }}
				form={form}
			>
				<Form.Item
					label={t('form.label.name')}
					name='name'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						}
					]}
				>
					<Input placeholder={t('form.placeholders.name')} />
				</Form.Item>
				<Form.Item
					label={t('form.label.description')}
					name='description'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						}
					]}
				>
					<Input.TextArea placeholder={t('form.placeholders.description')} autoSize />
				</Form.Item>
			</Form>
		</Modal>
	)
}

TypesForm.propTypes = {
	messageApi: PropTypes.object.isRequired,
	actionId: PropTypes.string.isRequired,
	isModalTypeOpen: PropTypes.bool.isRequired,
	setIsModalTypeOpen: PropTypes.func.isRequired,
	updateType: PropTypes.bool.isRequired,
	typeToUpdate: PropTypes.object.isRequired,
	filters: PropTypes.object.isRequired
}

export default TypesForm;
