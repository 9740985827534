import React from 'react';

// Packages
import { Badge, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const IngredientsList = (props) => {
	const { ingredients } = props;

	const { t } = useTranslation();

	return (
		<Space direction='vertical'>
			{ingredients !== undefined && ingredients.length > 0
				?	ingredients.map((ingredient, index) => (
						<Badge
							key={index}
							color='#F18B71'
							text={`${ingredient.properties.quantity} ${ingredient.properties.unit.abbreviation} ${ingredient.ingredient.name}`}
						/>
					))
				:	<Typography.Text type='secondary'>{t('table.error.noData')}</Typography.Text>
			}
		</Space>
	)
}

IngredientsList.propTypes = {
	ingredients: PropTypes.array
}

const ItemsList = (props) => {
	const { items } = props;

	const { t } = useTranslation();

	return (
		<Space direction='vertical'>
			{items !== undefined && items.length > 0
				?	items.map((item, index) => (
						<Badge
							key={index}
							color='#F18B71'
							text={`${item.name}`}
						/>
					))
				:	<Typography.Text type='secondary'>{t('table.error.noData')}</Typography.Text>
			}
		</Space>
	)
}

ItemsList.propTypes = {
	items: PropTypes.array
}

export { IngredientsList, ItemsList };
