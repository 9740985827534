import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

// Packages
import { Badge, Card, Popconfirm, Typography } from 'antd';
import { CopyOutlined, DeleteOutlined, EditOutlined, QuestionCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import Axios from 'axios';
import PropTypes from 'prop-types';

// Components
import Icon from '../../utils/Icon';

// Actions
import { type_delete } from '../../../redux/actions/typesActions';

// Utils
import create_query_params from '../../../utils/create_query_params';

const TypeInfo = (props) => {
	const { messageApi, actionId, type, setIsModalTypeOpen, setUpdateType, setTypeToUpdate } = props;

	const { action_id } = useParams();

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const navigate = useNavigate();

	const { type_delete_loading } = useSelector(type => type.types);

	const [hasHaptics, setHasHaptics] = useState(false);

	useEffect(() => {	
		let isMounted = true;

		const filters = { action: type._id.$oid };

		Axios.get(process.env.REACT_APP_SERVER_URL + '/api/haptics/multiple?' + create_query_params(filters))
		.then((response) => {
			if (isMounted) {
				if (response.data.count > 0) {
					setHasHaptics(true);
				}
				else {
					setHasHaptics(false);
				}
			}
		})
		.catch(() => {
			if (isMounted) {
				setHasHaptics(false);
			}
		});

		return () => {
			isMounted = false;
		};
	}, [type]);

	const handleUpdateType = (record) => {
		setIsModalTypeOpen(true);
		setUpdateType(true);
		setTypeToUpdate(record);
	}

	const handleDeleteType = (record) => {
		const filters = { action: actionId };
		dispatch(type_delete(messageApi, record._id.$oid, filters));
	}

	return (
		<div>
			<Card
				style={{ height: 170 }}
				actions={[
					<CopyOutlined key='copy' onClick={() => navigator.clipboard.writeText(type._id.$oid)} />,
					<div
						key={type._id.$oid}
						onClick={() => navigate(`/actions/${action_id}/details/type/${type._id.$oid}`)}
					>
						<Badge className='anticon' dot={hasHaptics}>
							<Icon icon='edgesensor_high' size={18} />
						</Badge>
					</div>,
					<EditOutlined key='edit' onClick={() => handleUpdateType(type)} />,
					<Popconfirm
						title={t('actions.types.delete.confirmTitle')}
						description={t('actions.types.delete.confirmText')}
						icon={<QuestionCircleFilled style={{ color: '#1677FF' }} />}
						onConfirm={() => handleDeleteType(type)}
						okButtonProps={{
							loading: type_delete_loading
						}}
						okText={t('buttons.confirm')}
						cancelText={t('buttons.cancel')}
					>
						<DeleteOutlined />
					</Popconfirm>
				]}
			>
				<Card.Meta
					style={{ minHeight: '81.94px' }}
					title={type.name}
					description={
						<Typography.Paragraph
							style={{ marginBottom: '0.3rem' }}
							type='secondary'
							title={type.description}
							ellipsis={{ rows: 2 }}
						>
							{type.description}
						</Typography.Paragraph>
					}
				/>
			</Card>
		</div>
	)
}

TypeInfo.propTypes = {
	messageApi: PropTypes.object.isRequired,
	actionId: PropTypes.string.isRequired,
	type: PropTypes.object.isRequired,
	setIsModalTypeOpen: PropTypes.func.isRequired,
	setUpdateType: PropTypes.func.isRequired,
	setTypeToUpdate: PropTypes.func.isRequired
}

export default TypeInfo;
